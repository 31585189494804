/** @format */

import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './index.scss';
import Tooltip from '@mui/material/Tooltip';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { AlertData } from '../ServiceAlertDataTypes/ServiceAlertDataTypes';
import Modal from '@mui/material/Modal';
import ModalPopup from '../service-alert-modal/modal_Popup';
import SensorsIcon from '@mui/icons-material/Sensors';
import { getLocalStorageData } from '../../../utils/useLocalStorage';

function getColorBasedOnstatus(input: string) {
    var style = '';
    if (input === 'PENDING') {
        style = 'color-orange';
    } else if (input === 'REJECTED') {
        style = 'color-red';
    } else {
        style = 'color-green';
    }

    return style;
}

function getBorderColorBasedOnStatus(status: string) {
    var style = '';
    if (status === 'PENDING') {
        style = 'color-orange-border';
    } else if (status === 'REJECTED') {
        style = 'color-red-border';
    } else {
        style = 'color-green-border';
    }

    return style;
}

function stringToColor(string: string, index: number) {
    var color = '';
    if (index === 0 || index === 2) {
        color = '#6A5ABC';
    } else {
        color = '#008CF8';
    }
    return color;
}

function stringAvatar(name: string, index: number) {
    return {
        sx: {
            bgcolor: stringToColor(name, index),
            fontFamily: 'appMedium',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

interface PestNotification extends AlertData {
    handleOnClick?: () => void;
    reloadData?: () => void;
    lookUpData: string[];
    lastaction?: any;
    rejecteddate?: any;
    approveddate?: any;
    sprayname?: any;
    vendor?: any;
    selectedCountry?:any,
    selectedLob?:any
}

const PestNotification: React.FC<PestNotification> = ({
    msgId,
    type,
    crop,
    risk,
    message,
    reas,
    agrireas,
    identifier,
    status,
    farmer,
    consultants,
    createdtime,
    Location,
    lookUpData,
    handleOnClick,
    reloadData,
    product,
    approvers,
    lastaction,
    rejecteddate,
    approveddate,
    sprayname,
    vendor,
    selectedCountry,
    selectedLob
}) => {
    const dayjs = require('dayjs');
    var utc = require('dayjs/plugin/utc');
    var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

    dayjs.extend(utc);
    dayjs.extend(timezone);

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    let tz = loggedUser?.countrycode === 'TH'?'Asia/Bangkok':'Asia/Kolkata'
    const dayjs_date = dayjs(createdtime).tz(tz).format('DD MMM | LT');

    const subscribers = farmer.concat(consultants);
    const [modalOpen, setModalOpen] = useState<any>(false);
    const [open, setOpen] = React.useState(false);
    const handleCardClick = () => {
        setModalOpen(true);
        setOpen(true);
    };

    const handleModalClose = () => {
        loadData();
        setModalOpen(false);
        
    };

    const loadData = () => {
        
        reloadData?.();
    };

    

    return (
        <>
            {modalOpen && (
                <Modal
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <ModalPopup
                        messageId={msgId}
                        status={status}
                        farmers={farmer}
                        consultants={consultants}
                        alertMessage={message}
                        notificationType='PEST'
                        comm={reas}
                        agricomm={agrireas}
                        handleOnClose={handleModalClose}
                        loadData={loadData}
                        lookUpData={lookUpData}
                        prod={product}
                        approvers={approvers}
                        lastaction={lastaction}
                        rejecteddate={rejecteddate}
                        approveddate={approveddate}
                        sprayname={sprayname}
                        selectedCountry={selectedCountry}
                            selectedLob={selectedLob}
                    />
                </Modal>
            )}
            <div>
                <Card
                    className={
                        'card_class_pest ' + getBorderColorBasedOnStatus(status)
                    }
                    onClick={handleCardClick}>
                    <CardContent>
                        <div className='top_content'>
                            <div className='pest_identifier'>
                                <b>{identifier.replace('_', ' ')} </b>
                                <span className='identifier_part'>Pest</span>
                            </div>
                            <div className={getColorBasedOnstatus(status)}>
                                {status}
                            </div>
                        </div>
                        <div className='pest_crop'>
                            <b>{crop}</b>
                        </div>
                        <div className='farmer_list_icon'>
                            <div className='Farmer_count'>
                                <AvatarGroup max={3}>
                                    {subscribers.map((item, index) => (
                                        <Avatar
                                            key={
                                                item.farmerid
                                                    ? item.farmerid
                                                    : item.userid
                                            }
                                            {...stringAvatar(
                                                item.firstname +
                                                    ' ' +
                                                    item.lastname,
                                                index
                                            )}
                                        />
                                    ))}
                                </AvatarGroup>
                                <div className='Farmer_suffix'>
                                    {subscribers.length > 1
                                        ? 'Subscribers'
                                        : subscribers.length === 0
                                        ? 'No Subscribers'
                                        : 'Subscribers'}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}>
                                {risk === 'HIGH'
                                    ? renderSvg(
                                          svgIconName.diseaseAlertIcon,
                                          30
                                      )
                                    : risk === 'High'
                                    ? renderSvg(
                                          svgIconName.diseaseAlertIcon,
                                          30
                                      )
                                    : risk === 'MEDIUM'
                                    ? renderSvg(
                                          svgIconName.disease_alert_yellow,
                                          30
                                      )
                                    : risk === 'Medium'
                                    ? renderSvg(
                                          svgIconName.disease_alert_yellow,
                                          30
                                      )
                                    : risk === 'LOW'
                                    ? renderSvg(
                                          svgIconName.disease_alert_blue,
                                          30
                                      )
                                    : risk === 'Low'
                                    ? renderSvg(
                                          svgIconName.disease_alert_blue,
                                          30
                                      )
                                    : renderSvg(
                                          svgIconName.disease_alert_gray,
                                          30
                                      )}
                            </div>
                        </div>
                        <div>
                            <Tooltip title={message} arrow>
                                <p className='pest_description'>{message}</p>
                            </Tooltip>
                        </div>
                        <div className='pest_location'>
                            <img
                                src={svgIconName.map_pin.source}
                                width={'10px'}
                                alt='location'
                            />
                            <Tooltip title={Location} arrow>
                                <div className='pest_location_name'>
                                    {Location}
                                </div>
                            </Tooltip>
                        </div>
                        <div className='bottom_card_pest'>
                            <div className='time_font_pest'>{dayjs_date}</div>
                            <div className='alert_icon_pest'>
                                <SensorsIcon id='sensor-icon' />

                                <div>
                                    <p className='alert_type_pest'>{vendor}</p>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default PestNotification;
