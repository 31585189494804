/** @format */

import { Fragment, useState, useEffect } from 'react';
import CustomTabs from '../../../../components/ui/CustomTabs';
import { experimentalStyled as styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import LocationSvg from '../../../../assets/img/svg/reusable/map_pin.svg';
import EditIcon from '@mui/icons-material/Edit';
import './index.scss';
import {
    illusName,
    renderSvg,
    svgIconName,
} from '../../../../assets/img/imageExt';
import Stack from '@mui/material/Stack';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import moment from 'moment';
import { findCropImg } from '../../../../utils/helper';
import _ from 'lodash';
import VendorSerial from './VendorSerial';
import { getLocalStorageData } from '../../../../utils/useLocalStorage';
import TextField from '@mui/material/TextField';
import { Button, InputBase, MenuItem, Modal, Select } from '@mui/material';
import ModalPopup from '../../../device-management/popup-modal/ModalPopup';
import { Alert } from '../../../../utils/toastr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { getRequest } from '../../../../services/apiUtils';
import Loader from '../../../../components/ui/Loader';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    //maxWidth: 380,
    maxHeight: 'max-content',
    // [theme.breakpoints.down('sm')]: {
    //     maxWidth: 205
    // },
}));
const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(1),
    '& .MuiCardHeader-title': {
        color: '#10384F',
        // fontSize: '0.80rem',
        fontSize: '15px',
    },
    '& .MuiCardHeader-subheader': {
        color: '#696969',
        lineHeight: 1,
        fontSize: '0.70rem',
    },
}));

const tabData = [
    { label: 'Farm Location', value: 'location' },
    // { label: 'Nearby Devices', value: 'device' },
];

interface Props {
    user: any;
    editOrsave: any;
    updateObj: (arg1: any, arg2: any, arg3: any) => void;
    geolevelvalues: any
}
/**
 *Farmer Tabs (Farmer location ) Functional Component
 * @param props
 * @returns
 */
const FarmTabs: React.FC<Props> = ({ user, editOrsave, updateObj, geolevelvalues }) => {
 
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    let editOrSave = editOrsave;
    const dayjs = require('dayjs');
    const [dateValue, setDateValue] = useState<any>(user.cropsgrown.map((us:any) => {
        return dayjs(us.sowingdate);
    }))

    const [season, setSeason] = useState<any>(user.cropsgrown.map((us:any) => {
        return us.season;
    }))
    const [variety, setVariety] = useState<any>(user.cropsgrown.map((us:any) => {
        return us.variety;
    }))

    const [tab, setTab] = useState<string>('location');

    const getTabsValue = (valueFL: string) => {
        setTab(valueFL);
    };
    const [croplist, setCroplist] = useState<any>([]);
    const [seasonlist, setSeasonList] = useState<any>([]);
    const [varietylist, setVarietyList] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(true);
    const [varietyMap, setVarietyMap] = useState<Map<any, any>>(new Map());
    const [minDate, setMinDate] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [maxDate, setMaxDate] = useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(1, 'year');
        return currentDate;
    });
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const devicesLength = user?.devices ? user?.devices.length : 0;
    const [error, setError] = useState({
        flag: false,
        id: null
    })
    const [initialload, setInitialload] = useState(true)

    const nextDevice = () => {
        setCurrentIndex(
            currentIndex === devicesLength - 1 ? 0 : currentIndex + 1
        );
    };

    const prevDevice = () => {
        setCurrentIndex(
            currentIndex === 0 ? devicesLength - 1 : currentIndex - 1
        );
    };

    useEffect(() => {
        getLookup();
    }, []);

    useEffect(() => {
        if(croplist.length>0 && initialload) {
            setInitialload(false)
            getVarietyLookup()
            
        }
    }, [croplist])

    const getLookup = () => {
        // user.cropsgrown && user.cropsgrown.map((crp:any) => {

        
            let obj1 = {
                countrycode: loggedUser?.countrycode,
                lob: loggedUser.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser?.lineofbusiness,
                year: dayjs().year()
            };


            if(loggedUser.roleid === 'SUPER_ADMIN' && user.countrycode !== 'IN'){
                obj1= {...obj1,
                    lob:user.lineofbusiness,
                    countrycode:user.countrycode
                }
            }

            if(loggedUser.roleid === 'SUPER_ADMIN' && user.countrycode !== 'IN'){
                obj1= {...obj1,
                    lob:user.lineofbusiness,
                    countrycode:user.countrycode
                }
            }

            getRequest({
                url: 'lookup/master',
                method: 'GET',
                queryParams: obj1,
            }).then((resp: any) => {
                if (resp.statusflag) {
                    setCroplist(resp?.body?.data?.croplist);
                    setSeasonList(resp.body.data.seasonlist)
                    // setSeasonList((oldArray: any) => [
                    //     ...oldArray,
                    //     resp.body.data.seasonlist,
                    // ])
                    
                }
            });
        // })
    };
    
    const getVarietyLookup = () => {
        croplist && croplist.map((vari:any) => {
            let obj2 = {
                countrycode: loggedUser?.countrycode,
                lob: loggedUser.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser?.lineofbusiness,
                loggeduserrole: loggedUser?.roleid,
                cropid: vari.cropid,
            };


            if(loggedUser.roleid === 'SUPER_ADMIN' && user.countrycode !== 'IN'){
                obj2= {...obj2,
                    lob:user.lineofbusiness,
                    countrycode:user.countrycode
                }
            }
            getRequest({
                url: 'cropcalendar/lookup/master',
                method: 'GET',
                queryParams: obj2,
            }).then((response: any) => {
                if (response.statusflag) {
                    let tempmap = varietyMap;
                    tempmap.set(
                        vari.cropid,
                        response.body.data.cropvariety
                    );
                    setVarietyMap(tempmap);
                    setVarietyList((oldArray: any) => [
                        ...oldArray,
                        response.body.data.cropvariety,
                    ]);
                    setLoader(false);
                }
            });
        })
    }

   

    const handleCalendarChange = (
        newValue: Dayjs | null,
        field: any,
        ind: any
    ) => {
        const dayjs_date = dayjs(newValue);
        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue: any = year + '-' + month + '-' + date;
        // seasonlist[ind] = []
        // setSeasonList([...seasonlist])
        dateValue[ind] = newValue
        setDateValue([...dateValue])
        updateObj(dayjs(dateFilterValue).format('YYYY/MM/DD'), field, ind);
        // season[ind] = ''
        // setSeason([...season])
        // updateObj('', 'Season', ind)
        // getSeasonLookup(ind, year)
    };

    return (
        <>
            {loader && <Loader />}
            <div className='farmer-tabs'>
                {/* <CustomTabs tabData={tabData} getSeletedValue={getTabsValue} /> */}
                <div className='farm-content'>
                    <div className='card-list'>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 2, lg: 2, xl: 12 }}
                            columns={{ xs: 2, sm: 6, md: 6, lg: 12, xl: 12 }}
                            direction='column'>
                            {tab === 'location' && (
                                <Fragment>
                                    {user?.cropsgrown &&
                                        user?.cropsgrown.map(
                                            (valueFL: any, index: number) => {
                                                let condSvg = findCropImg(
                                                    valueFL?.cropname
                                                );
                                                return (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        key={index}
                                                        className='locationFL'>
                                                        <Item
                                                            className='farmloc_carddiv'
                                                            elevation={3}>
                                                            <StyledCardHeader
                                                                avatar={
                                                                    editOrSave !==
                                                                    'save' ? (
                                                                        <img
                                                                            alt={
                                                                                valueFL.cropname
                                                                            }
                                                                            src={
                                                                                condSvg
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span className='nameFL'>
                                                                            Crop:
                                                                        </span>
                                                                    )
                                                                }
                                                                action={
                                                                    <div
                                                                        className={
                                                                            editOrSave ===
                                                                            'save'
                                                                                ? 'farmloc_contentdiv_edit'
                                                                                : 'farmloc_contentdiv'
                                                                        }>
                                                                        <span className='nameFL'>
                                                                            Acreage:
                                                                        </span>
                                                                        <span
                                                                            className='valueFL'
                                                                            title={
                                                                                valueFL?.acerage
                                                                            }>
                                                                            {editOrSave ===
                                                                            'save' ? (
                                                                                <TextField
                                                                                    className='farmer_details_linebreak'
                                                                                    variant='standard'
                                                                                    defaultValue={
                                                                                        valueFL?.acerage
                                                                                    }
                                                                                    size='small'
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        updateObj(
                                                                                            event
                                                                                                .target
                                                                                                .value,
                                                                                            'Acreage',
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                valueFL?.acerage
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                title={
                                                                    editOrSave ===
                                                                    'save' ? (
                                                                        <span>
                                                                            <Select
                                                                                className='farmer-cropname'
                                                                                variant='standard'
                                                                                defaultValue={
                                                                                    valueFL.cropid +
                                                                                    '-' +
                                                                                    valueFL.cropname
                                                                                }
                                                                                size='small'
                                                                                style={{
                                                                                    color:
                                                                                        'darkblue',
                                                                                    fontFamily:
                                                                                        'appItalic',
                                                                                    fontSize:
                                                                                        '11px',
                                                                                    textAlign:
                                                                                        'left',
                                                                                    paddingLeft:
                                                                                        '8px',
                                                                                }}
                                                                                onChange={(
                                                                                    evt: any
                                                                                ) => {
                                                                                    // getVarietyLookup()
                                                                                    updateObj(
                                                                                        evt
                                                                                            .target
                                                                                            .value,
                                                                                        'Crop Name',
                                                                                        index
                                                                                    );
                                                                                    variety[index] = ''
                                                                                    updateObj(
                                                                                        '',
                                                                                        'Variety',
                                                                                        index
                                                                                    );
                                                                                    setVarietyList(
                                                                                        [
                                                                                            ...varietylist,
                                                                                        ]
                                                                                    );
                                                                                }}
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        sx: {
                                                                                            '& .MuiMenuItem-root': {
                                                                                                fontSize: 13,
                                                                                                lineHeight: 1.5,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                }}>
                                                                                {croplist &&
                                                                                    croplist.map(
                                                                                        (
                                                                                            option: any
                                                                                        ) =>
                                                                                            option.cropname !==
                                                                                            valueFL.cropname ? (
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        option.cropid +
                                                                                                        '-' +
                                                                                                        option.cropname
                                                                                                    }>
                                                                                                    {
                                                                                                        option.cropname
                                                                                                    }
                                                                                                </MenuItem>
                                                                                            ) : (
                                                                                                <MenuItem
                                                                                                    disabled
                                                                                                    value={
                                                                                                        option.cropid +
                                                                                                        '-' +
                                                                                                        option.cropname
                                                                                                    }>
                                                                                                    {
                                                                                                        option.cropname
                                                                                                    }
                                                                                                </MenuItem>
                                                                                            )
                                                                                    )}
                                                                            </Select>
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            title={
                                                                                valueFL?.cropname
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                            }}>
                                                                            {
                                                                                valueFL.cropname
                                                                            }
                                                                        </span>
                                                                    )
                                                                }
                                                            />
                                                            <CardContent className='card-team'>
                                                                <Stack
                                                                    spacing={2}
                                                                    direction='row'
                                                                    justifyContent='space-between'
                                                                    alignItems='center'
                                                                    mb={1}>
                                                                    <div
                                                                        className={
                                                                            editOrSave ===
                                                                            'save'
                                                                                ? 'farmloc_contentdiv_edit'
                                                                                : 'farmloc_contentdiv'
                                                                        }>
                                                                        <span className='nameFL'>
                                                                            Sowing
                                                                            Date:
                                                                        </span>
                                                                        <span
                                                                            className='valueFL'
                                                                            title={moment(
                                                                                valueFL?.sowingdate
                                                                            ).format(
                                                                                'DD MMM, YYYY'
                                                                            )}>
                                                                            {editOrSave ===
                                                                            'save' ? (
                                                                                <LocalizationProvider
                                                                                    dateAdapter={
                                                                                        AdapterDayjs
                                                                                    }>
                                                                                    <DatePicker
                                                                                        value={
                                                                                            dateValue[index]
                                                                                        }
                                                                                        minDate={minDate}
                                                                                        maxDate={maxDate}
                                                                                        onChange={(
                                                                                            newValue: any
                                                                                        ) =>
                                                                                            handleCalendarChange(
                                                                                                newValue,
                                                                                                'Sowing Date',
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        renderInput={(
                                                                                            params
                                                                                        ) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                className='myDatePicker farmloc_date'
                                                                                                variant='standard'
                                                                                                helperText={
                                                                                                    null
                                                                                                }
                                                                                                sx={{
                                                                                                    borderBottom: 1,
                                                                                                    width: 135,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            ) : (
                                                                                moment(
                                                                                    valueFL?.sowingdate
                                                                                ).format(
                                                                                    'DD MMM, YYYY'
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            editOrSave ===
                                                                            'save'
                                                                                ? 'farmloc_contentdiv_edit'
                                                                                : 'farmloc_contentdiv'
                                                                        }>
                                                                        <span className='nameFL'>
                                                                            Variety:
                                                                        </span>
                                                                        <span className='valueFL'>
                                                                            {editOrSave ===
                                                                            'save' ? (
                                                                                <Select
                                                                                    className='farmer-cropname'
                                                                                    variant='standard'
                                                                                    defaultValue={
                                                                                        variety[index]
                                                                                    }
                                                                                    title={
                                                                                        variety[index]
                                                                                    }
                                                                                    style={{
                                                                                        color:
                                                                                            'darkblue',
                                                                                        fontFamily:
                                                                                            'appItalic',
                                                                                        fontSize:
                                                                                            '11px',
                                                                                        textAlign:
                                                                                            'left',
                                                                                        paddingLeft:
                                                                                            '8px',
                                                                                    }}
                                                                                    size='small'
                                                                                    onChange={(
                                                                                        evt: any
                                                                                    ) => {
                                                                                        updateObj(
                                                                                            evt
                                                                                                .target
                                                                                                .value,
                                                                                            'Variety',
                                                                                            index
                                                                                        );
                                                                                        variety[index] = evt
                                                                                            .target
                                                                                            .value
                                                                                        setVariety([...variety])
                                                                                    }}
                                                                                    MenuProps={{
                                                                                        PaperProps: {
                                                                                            sx: {
                                                                                                '& .MuiMenuItem-root': {
                                                                                                    fontSize: 11,
                                                                                                    lineHeight: 1.5,
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}>
                                                                                    {varietylist &&
                                                                                        varietylist
                                                                                            .flat()
                                                                                            .map(
                                                                                                (
                                                                                                    option: any
                                                                                                ) => {
                                                                                                    if (
                                                                                                        parseInt(
                                                                                                            option.cropid
                                                                                                        ) ===
                                                                                                        valueFL.cropid
                                                                                                    ) {
                                                                                                        return option.variety !==
                                                                                                            variety[index] ? (
                                                                                                            <MenuItem
                                                                                                                value={
                                                                                                                    option.variety
                                                                                                                }>
                                                                                                                {
                                                                                                                    option.variety
                                                                                                                }
                                                                                                            </MenuItem>
                                                                                                        ) : (
                                                                                                            <MenuItem
                                                                                                                disabled
                                                                                                                value={
                                                                                                                    option.variety
                                                                                                                }>
                                                                                                                {
                                                                                                                    option.variety
                                                                                                                }
                                                                                                            </MenuItem>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                </Select>
                                                                            ) : (
                                                                                valueFL?.variety
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </Stack>
                                                                <Stack
                                                                    spacing={2}
                                                                    direction='row'
                                                                    justifyContent='space-between'
                                                                    alignItems='center'>
                                                                    <div
                                                                        className={
                                                                            editOrSave ===
                                                                            'save'
                                                                                ? 'farmloc_contentdiv_edit'
                                                                                : 'farmloc_contentdiv'
                                                                        }>
                                                                        <span className='nameFL'>
                                                                            Season:
                                                                        </span>
                                                                        <span
                                                                            className='valueFL'
                                                                            title={
                                                                                valueFL?.season
                                                                            }>
                                                                            {editOrSave ===
                                                                            'save' ? (
                                                                                <Select
                                                                                    className='farmer-cropname'
                                                                                    variant='standard'
                                                                                    defaultValue={
                                                                                        season[index]
                                                                                    }
                                                                                    style={{
                                                                                        color:
                                                                                            'darkblue',
                                                                                        fontFamily:
                                                                                            'appItalic',
                                                                                        fontSize:
                                                                                            '11px',
                                                                                        textAlign:
                                                                                            'left',
                                                                                        paddingLeft:
                                                                                            '8px',
                                                                                    }}
                                                                                    size='small'
                                                                                    onChange={(
                                                                                        evt: any
                                                                                    ) => {
                                                                                        updateObj(
                                                                                            evt
                                                                                                .target
                                                                                                .value,
                                                                                            'Season',
                                                                                            index
                                                                                        )
                                                                                        season[index] = evt
                                                                                            .target
                                                                                            .value
                                                                                        setSeason([...season])
                                                                                    }}
                                                                                    MenuProps={{
                                                                                        PaperProps: {
                                                                                            sx: {
                                                                                                '& .MuiMenuItem-root': {
                                                                                                    fontSize: 11,
                                                                                                    lineHeight: 1.5,
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                    }}>
                                                                                    {seasonlist &&
                                                                                        seasonlist.map(
                                                                                            (
                                                                                                option: any
                                                                                            ) =>
                                                                                                option.season !==
                                                                                                season[index] ? (
                                                                                                    <MenuItem
                                                                                                        value={
                                                                                                            option.season
                                                                                                        }>
                                                                                                        {
                                                                                                            option.season
                                                                                                        }
                                                                                                    </MenuItem>
                                                                                                ) : (
                                                                                                    <MenuItem
                                                                                                        disabled
                                                                                                        value={
                                                                                                            option.season
                                                                                                        }>
                                                                                                        {
                                                                                                            option.season
                                                                                                        }
                                                                                                    </MenuItem>
                                                                                                )
                                                                                        )}
                                                                                </Select>
                                                                            ) : (
                                                                                valueFL?.season
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </Stack>
                                                                {error.flag && error.id === index &&
                                                                    <div className='farmloc_err_text'>
                                                                        No Season available for selected year
                                                                    </div>
                                                                }
                                                                <div className='userlist-cropstage'>
                                                                    <Stack>
                                                                        <div className='cropstage-user'>
                                                                            <div className='lat-long-userlist'>
                                                                                <div
                                                                                    className={
                                                                                        editOrSave ===
                                                                                        'save'
                                                                                            ? 'farmloc_contentdiv_edit'
                                                                                            : 'farmloc_contentdiv'
                                                                                    }>
                                                                                    <span className='nameFL'>
                                                                                        lat:
                                                                                    </span>
                                                                                    <span
                                                                                        className='valueFL'
                                                                                        title={
                                                                                            valueFL?.latitude
                                                                                        }>
                                                                                        {editOrSave ===
                                                                                        'save' ? (
                                                                                            <TextField
                                                                                                className='farmer_details_linebreak'
                                                                                                variant='standard'
                                                                                                defaultValue={
                                                                                                    valueFL?.latitude
                                                                                                }
                                                                                                size='small'
                                                                                                onChange={(
                                                                                                    event: any
                                                                                                ) => {
                                                                                                    updateObj(
                                                                                                        event
                                                                                                            .target
                                                                                                            .value,
                                                                                                        'Latitude',
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            valueFL?.latitude
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        editOrSave ===
                                                                                        'save'
                                                                                            ? 'farmloc_contentdiv_edit'
                                                                                            : 'farmloc_contentdiv'
                                                                                    }>
                                                                                    <span className='nameFL'>
                                                                                        lng:
                                                                                    </span>
                                                                                    <span
                                                                                        className='valueFL'
                                                                                        title={
                                                                                            valueFL?.longitude
                                                                                        }>
                                                                                        {editOrSave ===
                                                                                        'save' ? (
                                                                                            <TextField
                                                                                                className='farmer_details_linebreak'
                                                                                                variant='standard'
                                                                                                defaultValue={
                                                                                                    valueFL?.longitude
                                                                                                }
                                                                                                size='small'
                                                                                                onChange={(
                                                                                                    event: any
                                                                                                ) => {
                                                                                                    updateObj(
                                                                                                        event
                                                                                                            .target
                                                                                                            .value,
                                                                                                        'Longitude',
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            valueFL?.longitude
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                </div>

                                                                                <div
                                                                                    className={
                                                                                        editOrSave ===
                                                                                        'save'
                                                                                            ? 'farmloc_contentdiv_edit'
                                                                                            : 'farmloc_contentdiv'
                                                                                    }>
                                                                                    <span className='nameFLcrop'>
                                                                                        CropStage:
                                                                                    </span>
                                                                                    <span className='valueFL'>
                                                                                        {
                                                                                            valueFL?.cropstageslabel
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        editOrSave ===
                                                                                        'save'
                                                                                            ? 'farmloc_contentdiv_edit'
                                                                                            : 'farmloc_contentdiv'
                                                                                    }>
                                                                                    <span className='nameFL'>
                                                                                        StageDesc:
                                                                                    </span>
                                                                                    <span className='valueFL'>
                                                                                        {
                                                                                            valueFL?.cropstagedescription
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                        </div>
                                                                    </Stack>
                                                                    <Stack>
                                                                        <div className='farmer-vendor-device'>
                                                                            <div
                                                                                onClick={
                                                                                    prevDevice
                                                                                }>
                                                                                {renderSvg(
                                                                                    illusName.circularBackEnabled,
                                                                                    '30px'
                                                                                )}
                                                                            </div>
                                                                            <VendorSerial
                                                                                key={
                                                                                    currentIndex
                                                                                }
                                                                                deviceList={
                                                                                    user
                                                                                        ?.devices
                                                                                        ?.length &&
                                                                                    user
                                                                                        ?.devices[
                                                                                        currentIndex
                                                                                    ]
                                                                                }
                                                                            />
                                                                            <div
                                                                                onClick={
                                                                                    nextDevice
                                                                                }>
                                                                                {renderSvg(
                                                                                    illusName.circularNextEnabled,
                                                                                    '30px'
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Stack>
                                                                </div>
                                                            </CardContent>
                                                            <CardActions
                                                                disableSpacing>
                                                                <Stack
                                                                    direction='row'
                                                                    spacing={2}
                                                                    alignItems='baseline'>
                                                                    <img
                                                                        src={
                                                                            LocationSvg
                                                                        }
                                                                        alt='location'
                                                                    />
                                                                    <Typography
                                                                        variant='body2'
                                                                        color='text.secondary'
                                                                        sx={{
                                                                            fontSize:
                                                                                '0.75rem',
                                                                            textTransform:
                                                                                'capitalize',
                                                                            fontFamily:
                                                                                'appRegular',
                                                                            color:
                                                                                '#747474',
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                gap:
                                                                                    '20px',
                                                                            }}>
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        width:
                                                                                            'max-content',
                                                                                    }}>
                                                                                    {loggedUser.countrycode ===
                                                                                    'TH'  || user.countrycode === 'TH' ? (
                                                                                        <span>
                                                                                            {`${geolevelvalues?.subDistrict}, ${geolevelvalues?.district}, ${geolevelvalues?.state}`.replace(
                                                                                                /\w+/g,
                                                                                                _.capitalize
                                                                                            )}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span>
                                                                                            {`${geolevelvalues?.village}, ${geolevelvalues?.subDistrict}, ${geolevelvalues?.district}, ${geolevelvalues?.state}`.replace(
                                                                                                /\w+/g,
                                                                                                _.capitalize
                                                                                            )}{' '}
                                                                                        </span>
                                                                                    )}
                                                                                </span>
                                                                            </>
                                                                        </div>
                                                                    </Typography>
                                                                </Stack>
                                                            </CardActions>
                                                        </Item>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                </Fragment>
                            )}
                        </Grid>
                    </div>
                    <div></div>
                </div>
            </div>
        </>
    );
};

export default FarmTabs;
