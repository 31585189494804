import React from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';


function ForecastGraph(props:any) {

    const dayjs = require('dayjs');

    var utc = require('dayjs/plugin/utc');
    var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const updatedDateTime = dayjs().format('DD-MM-YYYY').toString();

    const forecastgraph = {
        labels: props.month,
        datasets: [
            {
                label: 'Temperature (in ℃)',
                data: props.temp,
                fill: true,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor:'rgba(255, 205, 86, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,

            },
            {
                label: 'Rainfall (in mm)',
                data: props.rainfall,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
            {
                label: 'Humidity (in %)',
                data: props.humidity,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor:'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
            {
                label: 'Windspeed (in km/h)',
                data: props.windspeed,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4
            },
        ],
    };


      
    const options = {
        scales: {
            x: {
              title: {
                  display: true,
                  text: updatedDateTime,
                  color: "#800000",
                }
          }
          },
        plugins: {
            tooltip: {
              callbacks: {
                label: function(item:any) {
                    console.log('item',item)
                    var datasetIndex = item.datasetIndex;
                    const units:any = {
                      0: " ℃",
                      1: " mm",
                      2: "%",
                      3:' km/h'
                    }
          
                    var unit = units[datasetIndex];
                    return `${item.formattedValue}${unit}`;
                  },
              }
            },
        
    }
      };
          
      

     

      
    return (
        <div>
            <div className='App'>
                <Line data={forecastgraph} options={options} />
            </div>
        </div>
    )
  
}

export default ForecastGraph;