/** @format */

import React from 'react';
import { TextField, Button, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './stageinfo.scss';
import NotificationMessage from './NotificationMessage'

function NotificationModal(props: any) {
    const [modalNotification, setModalNotification] = React.useState<boolean>(
        false
    );

    const handleModalCropClose = () => {
        setModalNotification(false);
    };

    return (
        <div>
            <Button
                onClick={() => {
                    setModalNotification(true);
                }}>
                Edit Notification
            </Button>
            <Modal
                open={modalNotification}
                onClose={() => setModalNotification(false)}>
                <div className='box-modal-createcrop'>
                    <div className='modal-create-notif-close'>
                        <div className='label-header-notif'>Notification</div>
                        <div>
                            <CloseIcon
                                onClick={handleModalCropClose}
                            />
                        </div>
                    </div>

                    {props.notificationArray &&
                        props.notificationArray.map((notifi: any, idx: any) => {
                            return (
                                <NotificationMessage notification={notifi} index={props.index} 
                                updateSubStageRow={props.updateSubStageRow} notificationArray={props.notificationArray}/>
                            );
                        })}
                </div>
            </Modal>
        </div>
    );
}

export default NotificationModal;
