/** @format */

const INITIAL_STATE: any = {
    role: [],

    lob: {
        inputList: [
            {
                LOB: '',
                NoofRoles: '',
                AssignedCrops: [],
                LobAvailable: [],
            },
        ],
    },
    serviceAlerts: {
        inputList: [
            {
                roleid: '',
                roledescription: '',
                rolename: '',
                weatherAlert: [],
                diseaseAlert: false,
                pestAlert: false,
                channelMode: [],
                saAvailable: [],
            },
        ],
    },
    selectedLanguages: [],

    countryDefaultData: [
        { label: 'Region', value: '' },
        { label: 'Cluster', value: '' },
        { label: 'Country Name - Code', value: '' },
        { label: 'Currency', value: '' },
    ],
};

const countryConfigReducer = (state = INITIAL_STATE, action: any): any => {
    const { payload, type } = action;
    switch (type) {
        case 'COUNTRY_CONFIG_ADD_ROLE_DEF_INPUTLIST': {
            const lob = payload;
            let arr = [...state.role];
            let arrInd = 0;

            arr.forEach((elem: any, index: any) => {
                if (elem.lobselect === lob) {
                    arrInd = index;
                }
            });

            let tempElem = { ...arr[arrInd] };
            let inputListNew = [...tempElem.inputList];
            inputListNew.push({
                refRole: '',
                sohRole: '',
                displayName: '',
                lineofbusiness: '',
                roleid: null,
                rolename: '',
            });
            tempElem.inputList = inputListNew;
            arr[arrInd] = tempElem;

            return {
                ...state,
                role: arr,
            };
        }

        case 'COUNTRY_CONFIG_REMOVE_ROLE_DEF_INPUTLIST': {
            const { index, lob, arg1, arg2 } = payload;
            let arr = [...state.role];
            let arrInd = 0;

            arr.forEach((elem: any, ind: any) => {
                if (elem.lobselect === lob) {
                    arrInd = ind;
                }
            });
            let elementsRemovedRef: any = [];
            let elementsRemovedSoh: any = [];
            let tempElem = { ...arr[arrInd] };
            tempElem.selectedRefData.forEach((toremoveRef: any, ind: any) => {
                if (toremoveRef !== arg1) {
                    elementsRemovedRef.push(toremoveRef);
                }
            });
            tempElem.selectedSohData.forEach((toremoveSoh: any, ind: any) => {
                if (toremoveSoh !== arg2) {
                    elementsRemovedSoh.push(toremoveSoh);
                }
            });
            let inputList = [...tempElem.inputList];
            let inputListRemoved: any = [];

            inputList.forEach((elem: any, i: any) => {
                if (i !== index) {
                    inputListRemoved.push(elem);
                }
            });

            tempElem.inputList = inputListRemoved;
            tempElem.selectedRefData = elementsRemovedRef;
            tempElem.selectedSohData = elementsRemovedSoh;
            arr[arrInd] = tempElem;

            return {
                ...state,
                role: arr,
            };
        }

        case 'COUNTRY_CONFIG_UPDATE_ROLE_DEF_INPUTLIST': {
            var tempRole = [...state.role];
            let arrInd = 0;
            tempRole.forEach((elem: any, ind: any) => {
                if (elem.lobselect === payload.lob) {
                    arrInd = ind;
                }
            });

            let tempElem = { ...tempRole[arrInd] };
            let arr1: any = [...tempElem.selectedRefData];
            let arr2: any = [...tempElem.selectedSohData];

            if (payload.feild === 'refRole') {
                arr1[payload.index] = payload.val;
                tempElem.selectedRefData = arr1;
                // console.log('tempelem 1', tempElem.selectedRefData)
            }
            if (payload.feild === 'sohRole') {
                arr2[payload.index] = payload.val;
                tempElem.selectedSohData = arr2;
                // tempElem.selectedSohData = [...tempElem.selectedSohData, payload.val]
            }

            let inputList = [...tempElem.inputList];
            inputList.forEach((elem: any, i: any) => {
                if (i === payload.index) {
                    inputList[i] = {
                        refRole:
                            payload.feild === 'refRole'
                                ? payload.val
                                : inputList[i].refRole,
                        sohRole:
                            payload.feild === 'sohRole'
                                ? payload.val
                                : inputList[i].sohRole,
                        displayName:
                            payload.feild === 'displayName'
                                ? payload.val
                                : inputList[i].displayName,
                        lineofbusiness: payload.lob,
                        roleid: payload.roleid,
                        rolename: payload.rolename,
                    };
                }
            });

            tempElem.inputList = inputList;
            tempRole[arrInd] = tempElem;

            return {
                ...state,
                role: tempRole,
            };
        }

        case 'COUNTRY_CONFIG_UPDATE_ROLE_DEF_BY_LOB': {
            let temparr: any = [];
            var existingarr = [...state.role];
            var tempLob = payload;
            var existingLob: any = [];

            state.role.forEach((val: any) => {
                existingLob.push(val.lobselect);
            });

            if (existingLob.length > tempLob.length) {


                let filt: any = tempLob.map((temp: any) =>
                    state.role.filter((fil: any) => fil.lobselect === temp)
                );
                existingarr = filt;
            } else {
                var lobToConsider = tempLob.filter((val: any) => {
                    return existingLob.indexOf(val) === -1;
                });
                lobToConsider.forEach((selected: any) => {
                    temparr.push({
                        lobselect: selected,
                        inputList: [
                            {
                                refRole: '',
                                sohRole: '',
                                displayName: '',
                                lineofbusiness: '',
                                roleid: null,
                                rolename: '',
                            },
                        ],
                        selectedRefData: [],
                        selectedSohData: [],
                    });
                });

                let len = existingarr.length;
                existingarr[len] = [...temparr];
            }

            return {
                ...state,
                role: existingarr.flat(),
            };
        }

        case 'ADD_LOB': {
            const lob: any = { ...state.lob };
            lob.inputList = payload;
            return {
                ...state,
                lob: lob,
            };
        }
        case 'REMOVE_LOB': {
            let tempForm = [...state.lob.inputList];
            tempForm.splice(payload, 1);
            const lob: any = { ...state.lob };
            lob.inputList = tempForm;
            return {
                ...state,
                lob: lob,
            };
        }
        case 'SAVE_METRIC_LOB': {
            let tempForm: any[] = [];

            state.lob.inputList.forEach((data: any, i: any) => {
                if (i === payload.index) {
                    tempForm[i] = {
                        LOB: payload.key === 'LOB' ? payload.val : data.LOB,
                        NoofRoles:
                            payload.key === 'ROLE'
                                ? payload.val
                                : data.NoofRoles,
                        AssignedCrops:
                            payload.key === 'CROPS'
                                ? payload.val
                                : data.AssignedCrops,
                        LobAvailable:
                            payload.key === 'lobAvailable'
                                ? payload.val
                                : data.LobAvailable,
                    };
                } else {
                    tempForm[i] = data;
                }
            });

            var lob: any = { ...state.lob };
            lob.inputList = tempForm;

            return {
                ...state,
                lob: lob,
            };
        }
        case 'COUNTRY_CONFIG_UPDATE_LANGUAGE_LIST': {
            if (state.selectedLanguages.includes(payload)) {
                const array = state.selectedLanguages.filter(
                    (language: any) => language !== payload
                );
                return {
                    ...state,
                    selectedLanguages: array,
                };
            } else {
                return {
                    ...state,
                    selectedLanguages: [...state.selectedLanguages, payload],
                };
            }
        }
        case 'COUNTRY_CONFIG_GET_STATIC_DATA': {
            return {
                ...state,
                countryDefaultData: [
                    { label: 'Region', value: payload.cluster },
                    { label: 'Cluster', value: payload.cluster },
                    {
                        label: 'Country Name - Code',
                        value: `${payload.country} - ${payload.countrycode}`,
                    },
                    {
                        label: 'Currency',
                        value: `${payload.currencyname} - ${payload.currencycode}`,
                    },
                ],
            };
        }
        case 'RESET_LOB_LIST': {
            return {
                ...state,
                lob: INITIAL_STATE.lob,
            };
        }
        case 'RESET_ROLE_DEFINITION': {
            let resetarr = state.role;
            resetarr.forEach((reset: any) => {
                reset.selectedRefData = [];
                reset.selectedSohData = [];
                reset.inputList = [
                    {
                        refRole: '',
                        sohRole: '',
                        displayName: '',
                        lineofbusiness: '',
                        roleid: null,
                        rolename: '',
                    },
                ];
            });
            return {
                ...state,
                role: resetarr,
            };
        }
        case 'RESET_COUNTRY_SETUP': {
            return {
                ...state,
                selectedLanguages: INITIAL_STATE.selectedLanguages,
            };
        }
        case 'ADD_SERVICE_ALERT': {
            const sa: any = { ...state.serviceAlerts };
            sa.inputList = payload;
            return {
                ...state,
                serviceAlerts: sa,
            };
        }
        case 'REMOVE_SERVICE_ALERT': {
            let tempForm = [...state.serviceAlerts.inputList];
            tempForm.splice(payload, 1);
            const serviceAlerts: any = { ...state.serviceAlerts };
            serviceAlerts.inputList = tempForm;
            return {
                ...state,
                serviceAlerts: serviceAlerts,
            };
        }
        case 'RESET_SERVICE_ALERT': {
            return {
                ...state,
                serviceAlerts: INITIAL_STATE.serviceAlerts,
            };
        }

        case 'SAVE_SERVICE_ALERT': {
            let tempForm: any[] = [];
            state.serviceAlerts.inputList.forEach((data: any, i: any) => {
                if (i === payload.index) {
                    tempForm[i] = {
                        roleid:
                            payload.key === 'roleid'
                                ? payload.val
                                : data.roleid,
                        roleName:
                            payload.key === 'rolename'
                                ? payload.val
                                : data.roleName,
                        roledescription:
                            payload.key === 'roledescription'
                                ? payload.val
                                : data.roledescription,
                        weatherAlert:
                            payload.key === 'weatherAlert'
                                ? payload.val
                                : data.weatherAlert,
                        diseaseAlert:
                            payload.key === 'diseaseAlert'
                                ? payload.val
                                : data.diseaseAlert,
                        pestAlert:
                            payload.key === 'pestAlert'
                                ? payload.val
                                : data.pestAlert,
                        channelMode:
                            payload.key === 'channelMode'
                                ? payload.val
                                : data.channelMode,
                        saAvailable:
                            payload.key === 'saAvailable'
                                ? payload.val
                                : data.saAvailable,
                    };
                } else {
                    tempForm[i] = data;
                }
            });

            var serviceAlerts: any = { ...state.serviceAlerts };
            serviceAlerts.inputList = tempForm;

            return {
                ...state,
                serviceAlerts: serviceAlerts,
            };
        }
        default:
            return state;
    }
};

export default countryConfigReducer;
