/** @format */

import React from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

function WeatherGraph(props: any) {
    const data = {
        labels: props.month,
        datasets: [
            {
                label: 'Temperature (in ℃)',
                data: props.temp,
                fill: true,
                backgroundColor: 'rgba(255, 205, 86, 0.2)',
                borderColor: 'rgb(255, 205, 86)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Humidity (in %)',
                data: props.humidity,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Rainfall (in mm)',
                data: props.rainfall,
                fill: true,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'WindSpeed (in km/h)',
                data: props.windspeed,
                fill: true,
                borderColor: 'rgb(153, 102, 255)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
        ],
    };

    const optionsallgraph = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item: any) {
                        console.log('item', item);
                        var datasetIndex = item.datasetIndex;
                        const units: any = {
                            0: '℃',
                            1: '%',
                            2: ' mm',
                            3: ' km/h',
                        };

                        var unit = units[datasetIndex];
                        return `${item.formattedValue}${unit}`;
                    },
                },
            },
        },
    };

    const tempgraph = {
        labels: props.Month,
        datasets: [
            {
                label: 'Minimum Temperature (in ℃)',
                data: props.mini,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Maximum Temperature (in ℃)',
                data: props.max,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Average Temperature (in ℃)',
                data: props.avg,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
        ],
    };

    const optionstemp = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item: any) {
                        var datasetIndex = item.datasetIndex;
                        const units: any = {
                            0: ' ℃',
                            1: ' ℃',
                            2: ' ℃',
                        };

                        var unit = units[datasetIndex];
                        return `${item.formattedValue}${unit}`;
                    },
                },
            },
        },
    };

    const humidgraph = {
        labels: props.Month,
        datasets: [
            {
                label: 'Minimum Humidity (in %)',
                data: props.mini,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Maximum Humidity (in %)',
                data: props.max,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Average Humidity (in %)',
                data: props.avg,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
        ],
    };

    const optionshumid = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item: any) {
                        var datasetIndex = item.datasetIndex;
                        const units: any = {
                            0: ' %',
                            1: ' %',
                            2: ' %',
                        };

                        var unit = units[datasetIndex];
                        return `${item.formattedValue}${unit}`;
                    },
                },
            },
        },
    };

    const windgraph = {
        labels: props.Month,
        datasets: [
            {
                label: 'Minimum Windspeed (in km/h)',
                data: props.mini,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Maximum Windspeed (in km/h)',
                data: props.max,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Average Windspeed (in km/h)',
                data: props.avg,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
        ],
    };

    const optionswind = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item: any) {
                        var datasetIndex = item.datasetIndex;
                        const units: any = {
                            0: ' km/h',
                            1: ' km/h',
                            2: ' km/h',
                        };

                        var unit = units[datasetIndex];
                        return `${item.formattedValue}${unit}`;
                    },
                },
            },
        },
    };

    const rainfallgraph = {
        labels: props.Month,
        datasets: [
            {
                label: 'Minimum Rainfall (in mm)',
                data: props.mini,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgb(54, 162, 235)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Maximum Rainfall (in mm)',
                data: props.max,
                fill: true,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Average Rainfall (in mm)',
                data: props.avg,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
            {
                label: 'Total Rainfall (in mm)',
                data: props.totrain,
                fill: true,
                borderColor: 'rgb(255, 205, 86)',
                backgroundColor: 'rgba(255, 205, 86, 0.2)',
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
            },
        ],
    };

    const optionsrainfall = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item: any) {
                        console.log('item', item);
                        var datasetIndex = item.datasetIndex;
                        const units: any = {
                            0: ' mm',
                            1: ' mm',
                            2: ' mm',
                            3: ' mm',
                        };

                        var unit = units[datasetIndex];
                        return `${item.formattedValue}${unit}`;
                    },
                },
            },
        },
    };

    return (
        <div>
            {props.currentAlertType === 'ALL' ? (
                <div className='App'>
                    <Line data={data} options={optionsallgraph} />
                </div>
            ) : props.currentAlertType === 'RAINFALL' ? (
                <div>
                    <Line data={rainfallgraph} options={optionsrainfall} />
                </div>
            ) : props.currentAlertType === 'TEMPERATURE' ? (
                <div>
                    <Line data={tempgraph} options={optionstemp} />
                </div>
            ) : props.currentAlertType === 'HUMIDITY' ? (
                <div>
                    <Line data={humidgraph} options={optionshumid} />
                </div>
            ) : props.currentAlertType === 'WINDSPEED' ? (
                <div>
                    <Line data={windgraph} options={optionswind} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default WeatherGraph;
