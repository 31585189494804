/** @format */

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { connect } from 'react-redux';
import { calendarChange } from '../../../redux/actions/calendar/action';
import {
    createSearchParams,
    useLocation
} from 'react-router-dom';
import { isNull } from 'lodash';

const Calendar = (props: any) => {
    var searchCriteria = useLocation().search;
    var urlSearchParams = new URLSearchParams(searchCriteria);

    const [minDate, setMinDate] = React.useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(2, 'year').toDate();
        return currentDate;
    });

    const [maxDate, setMaxDate] = React.useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs();
        return currentDate;
    });

    const [valueDate, setValueDate] = React.useState<Dayjs | null>(() => {
        if (props.calendarval !== null) {
            return props.calendarval;
        } else {
            if (urlSearchParams.has('monthyearfilter') && urlSearchParams.get('monthyearfilter')!== 'null') {
                var dateArr = urlSearchParams.get('monthyearfilter').split('-');
                const dayjs = require('dayjs');
                const month: number = +dateArr[0];
                var date = dayjs()
                    .year(dateArr[1])
                    .month(month - 1);
                return date;
            }
        }
    });

    

    
    const handleCalendarChange = (newValue: Dayjs | null) => {
        
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = month + '-' + year;
        

        if (!isNaN(month) && !isNaN(year)) {
            
            // handleFilter(dateFilterValue, 'monthyearfilter');
            props.handleCalendar(newValue);
            setValueDate(newValue);
            props.handleChange(dateFilterValue, 'monthyearfilter', true);
            // setParams(tableParams());
            // setSelectPageOne(true);
        } else {
            setValueDate(null);
            props.handleChange('ALL', 'monthyearfilter', false);

            // setIsPageFiltered(true);
        }

        // setIsPageFiltered(true);
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    views={['year', 'month']}
                    value={valueDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleCalendarChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className='myDatePicker'
                            variant='standard'
                            helperText={null}
                            sx={{ borderBottom: 1 }}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
};

const mapStateToProps = ({ calendar: { calendarValue } }: any) => {
    
    return {
        calendarval: calendarValue,
    };
};

const mapDispatchToProps = {
    handleCalendar: (val: any) => calendarChange(val),
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
