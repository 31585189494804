/** @format */

import { Button } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/img/illustration/dashboard-images/plus.svg';
import './index.scss';
import ModalCustom from './modalCustom';

interface ICreateNewAdvisory{
    setMakeAPICall:any;
}

function CreateNewAdvisory({
    setMakeAPICall
}:ICreateNewAdvisory) {
   
    const [open, setOpen] = useState(false);

    const handleOpenPopup = () => {
        
        setOpen(true);
    };

    return (
        <div className='modal-create-container'>
            <div className='modal-advisory-button'>
                <Button
                    className='advisory-new-button'
                    onClick={handleOpenPopup}>
                    <PlusIcon className='plus-advisory-icon' width={15} />
                    &nbsp;&nbsp;Create new Advisory
                </Button>
                <ModalCustom open={open} setOpen={setOpen} setMakeAPICall={setMakeAPICall}/>
        </div>
        </div>
    );
}

export default CreateNewAdvisory;
