/** @format */
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { getRequest } from '../../services/apiUtils';

const INITIAL_STATE: any = {
    basicInfo: {
        role: '',
        lob: '',
        inputFields: [
            {
                label: 'First Name',
                value: '',
                id: 'fName',
                type: 'text',
                optional: false,
            },
            {
                label: 'Last Name',
                value: '',
                id: 'lName',
                type: 'text',
                optional: false,
            },
            {
                label: 'Phone Number',
                value: '',
                id: 'phoneNo',
                type: 'number',
                optional: false,
            },
            {
                label: 'Email Id',
                value: '',
                id: 'emailId',
                type: 'text',
                optional: true,
            },
            {
                label: 'Vendor Code',
                value: '',
                id: 'vendorcode',
                type: 'text',
                optional: false,
            },
        ],
        countryCode: '',
        countryName: '',
        genderSelected: '',
        languageSelected: '',
        channelModesSelected: [],
        forecastSelected: [],
        dailyforecastalert: false,
        yesterdayforecastalert: false,
        smsSubscription: false,
        whatsAppSubscription: false,
    },
    locationMapping: {
        countryCode: '',
        countryName: '',
        lob: '',
        allgeolevels: [],
        geolevelCodes: [],
        address: '',
        zipCode: '',
        firstData: [],
        resetData: [],
    },
    landCropObj: [
        {
            cropid: '',
            cropname: '',
            variety: '',
            season: '',
            latitude: '',
            longitude: '',
            acreage: '',
            sowingdate: null,
            previousseasonyield: '',
            varietyList:[]
        },
    ],
    selectedCrops: [],
    acreage: '',
    dataPrivacyConsent: false,
    dataConsent: false,
};

const createUserReducer = (state = INITIAL_STATE, action: any): any => {
    const { payload, type } = action;
    switch (type) {
        case 'BASIC_INFO_PROVIDED': {
            const { infoName, value, label } = payload;
            let obj = state.basicInfo;

            switch (infoName) {
                case 'role': {
                    // obj.role = payload.value; //mistake
                    let obj1 = { ...obj, role: payload.value };
                    return { ...state, basicInfo: obj1 };
                }

                case 'countrycode': {
                    let obj1 = { ...obj, countryCode: value };
                    return { ...state, basicInfo: obj1 };
                }
                case 'countryname': {
                    let obj1 = { ...obj, countryName: value };
                    return { ...state, basicInfo: obj1 };
                }

                // case 'First Name': {
                case 'typeInput': {
                    const tempArr = state.basicInfo.inputFields;
                    const modifiedTempArr = tempArr.map((individual: any) =>
                        individual.label === label
                            ? { ...individual, value: value }
                            : { ...individual }
                    );
                    let tempObj = {
                        ...state.basicInfo,
                        inputFields: [...modifiedTempArr],
                    };
                    return { ...state, basicInfo: { ...tempObj } };
                }
                case 'gender': {
                    let obj1 = { ...obj, genderSelected: value };
                    return { ...state, basicInfo: obj1 };
                }
                case 'language': {
                    let obj1 = { ...obj, languageSelected: value };
                    return { ...state, basicInfo: obj1 };
                }
                case 'channelMode': {
                    let isPresent: boolean = state.basicInfo.channelModesSelected.includes(
                        value
                    );
                    let tempArr = [...state.basicInfo.channelModesSelected];
                    if (isPresent) {
                        let modifiedArray = tempArr.filter(
                            (indiLanguage) => indiLanguage !== value
                        );
                        let obj1 = {
                            ...obj,
                            channelModesSelected: [...modifiedArray],
                            smsSubscription: modifiedArray.includes('SMS'),
                            whatsAppSubscription: modifiedArray.includes(
                                'WhatsApp'
                            ),
                        };
                        return { ...state, basicInfo: obj1 };
                    } else {
                        tempArr.push(value);
                        let obj1 = {
                            ...obj,
                            channelModesSelected: [...tempArr],
                            smsSubscription: tempArr.includes('SMS'),
                            whatsAppSubscription: tempArr.includes('WhatsApp'),
                        };
                        return { ...state, basicInfo: obj1 };
                    }
                }

                case 'forecastMode': {
                    let isPresent: boolean = state.basicInfo.forecastSelected.includes(
                        value
                    );
                    let tempArr = [...state.basicInfo.forecastSelected];
                    if (isPresent) {
                        let modifiedArray = tempArr.filter(
                            (indiLanguage) => indiLanguage !== value
                        );
                        let obj1 = {
                            ...obj,
                            forecastSelected: [...modifiedArray],
                            dailyforecastalert: modifiedArray.includes(
                                'Daily Forecast Alert'
                            ),
                            yesterdayforecastalert: modifiedArray.includes(
                                'Yesterday Forecast Alert'
                            ),
                        };
                        return { ...state, basicInfo: obj1 };
                    } else {
                        tempArr.push(value);
                        let obj1 = {
                            ...obj,
                            forecastSelected: [...tempArr],
                            dailyforecastalert: tempArr.includes(
                                'Daily Forecast Alert'
                            ),
                            yesterdayforecastalert: tempArr.includes(
                                'Yesterday Forecast Alert'
                            ),
                        };
                        return { ...state, basicInfo: obj1 };
                    }
                }
                default:
                    break;
            }

            return {
                ...state,
            };
            // break;
        }

        case 'RESET_BASIC_INFO': {
            let obj = INITIAL_STATE.basicInfo;
            return { ...state, basicInfo: { ...obj } };
        }

        case 'GET_COUNTRY_DETAILS': {
            let countryObjAndLob: any = payload;
            let obj = { ...state.locationMapping };
            let obj1 = {
                ...obj,
                countryCode: countryObjAndLob.countryCode,
                countryName: countryObjAndLob.countryName,
            };

            let obj2 = { ...state.basicInfo };
            let obj3 = { ...obj2, lob: countryObjAndLob.lob };
            return { ...state, basicInfo: obj3, locationMapping: { ...obj1 } };
        }

        case 'LOCATION_LEVELS_PROVIDED': {
            const modifiedPayload = payload.map(
                (level: any) => level.selectedValue
            );
            const obj = state.locationMapping;
            const obj1 = {
                ...obj,
                geolevelCodes: [...modifiedPayload],
                allgeolevels: [...payload],
            };

            return { ...state, locationMapping: { ...obj1 } };
        }

        case 'LOCATION_INPUT_VALUES_PROVIDED': {
            switch (payload.inputName) {
                case 'address': {
                    const obj = { ...state.locationMapping };
                    const obj1 = { ...obj, address: payload.inputValue };
                    return { ...state, locationMapping: { ...obj1 } };
                }
                case 'zipCode': {
                    const obj = { ...state.locationMapping };
                    const obj1 = { ...obj, zipCode: payload.inputValue };
                    return { ...state, locationMapping: { ...obj1 } };
                }
                case 'lineofbusiness': {
                    const obj = { ...state.locationMapping };
                    const obj1 = { ...obj, lob: payload.inputValue };
                    return { ...state, locationMapping: { ...obj1 } };
                }
                default: {
                    return state;
                }
            }
        }

        case 'RESET_LOCATION_INPUTS': {
            let obj = { lob: '' };
            let obj1 = {
                ...obj,
                countryCode: '',
                countryName: '',
                geolevelCodes: [],
                firstData: [],
                resetData: [],
                address: '',
                zipCode: '',
                allgeolevels: [],
            };

            return { ...state, locationMapping: { ...obj1 } };
        }

        case 'FIRST_DATA_OR_RESET_DATA': {
            let obj = { ...state.locationMapping };
            const { dataType, data } = payload;
            if (dataType === 'firstData') {
                let obj1 = { ...obj, firstData: [...data] };
                return { ...state, locationMapping: { ...obj1 } };
            } else {
                let obj1 = { ...obj, resetData: [...data] };
                return { ...state, locationMapping: { ...obj1 } };
            }
        }

        case 'CREATE_USER_ADD_LAND_CROP_INFO': {
            let obj: any = {
                cropid: '',
                cropname: '',
                variety: '',
                season: '',
                latitude: '',
                longitude: '',
                acreage: '',
                sowingdate: null,
                previousseasonyield: '',
                varietyList:[]
            };
            let newobj: any = [...state.landCropObj, obj];

            return {
                ...state,
                landCropObj: newobj,
            };
        }

        case 'CREATE_USER_REMOVE_LAND_CROP_INFO': {
            const updatedObj: any = state.landCropObj.filter(
                (remove: any, ind: any) => ind !== payload.ind
            );

            const updatedcrops: any = state.selectedCrops.filter(
                (toRemove: any, i: any) => toRemove !== payload.crop
            );
            return {
                ...state,
                landCropObj: updatedObj,
                selectedCrops: updatedcrops,
            };
        }

        case 'CREATE_USER_UPDATE_LAND_CROP_INFO': {
            let temp = [...state.landCropObj];
            temp = temp.map((data: any, index: any) => {
                if (index === payload.i) {
                    return {
                        cropid:
                            payload.label === 'Crop'
                                ? payload.input
                                : temp[index].cropid,
                        cropname:
                            payload.label === 'Crop'
                                ? payload.name
                                : temp[index].cropname,
                        variety:
                            payload.label === 'Variety'
                                ? payload.input
                                : temp[index].variety,
                        season:
                            payload.label === 'Season'
                                ? payload.input
                                : temp[index].season,
                        latitude:
                            payload.label === 'Latitude'
                                ? payload.input
                                : temp[index].latitude,
                        longitude:
                            payload.label === 'Longitude'
                                ? payload.input
                                : temp[index].longitude,
                        acreage:
                            payload.label === 'Acreage'
                                ? payload.input
                                : temp[index].acreage,
                        sowingdate:
                            payload.label === 'Sowing Date'
                                ? payload.input
                                : temp[index].sowingdate,
                        previousseasonyield:
                            payload.label ===
                            'Prev. Season Yield (in Tonnes/Acer)'
                                ? payload.input
                                : temp[index].previousseasonyield,
                                varietyList:
                            payload.label ===
                            'variety-list'
                                ? payload.input
                                : temp[index].varietyList,
                    };
                }
                return data;
            });

            let newcrops: any = [...state.selectedCrops, payload.name];

            return {
                ...state,
                landCropObj: temp,
                selectedCrops: newcrops,
            };
        }
        case 'CREATE_USER_RESET_LAND_CROP_INFO': {
            let obj: any = [
                {
                    cropid: '',
                    cropname: '',
                    variety: '',
                    season: '',
                    latitude: '',
                    longitude: '',
                    acreage: '',
                    sowingdate: null,
                    previousseasonyield: '',
                    varietyList:[]
                },
            ];

            return {
                ...state,
                landCropObj: obj,
                acreage: '',
                selectedCrops: [],
            };
        }
        case 'UPDATE_ACREAGE': {
            return {
                ...state,
                acreage: payload.acreValue,
            };
        }
        case 'UPDATE_DATA_PRIVACY_CONSENT': {
            return {
                ...state,
                dataPrivacyConsent: payload.dataPrivacyConsent,
            };
        }
        case 'UPDATE_DATA_CONSENT': {
            return {
                ...state,
                dataConsent: payload.dataConsent,
            };
        }

        case 'RESET':
            return INITIAL_STATE;

        default: {
            return state;
        }
        case 'RESET_CONSENT_FORM': {
            let obj = INITIAL_STATE.dataPrivacyConsent;
            return { ...state, dataPrivacyConsent: { ...obj } };
        }
    }
};

export default createUserReducer;
