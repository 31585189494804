/** @format */

import { useEffect, useState } from 'react';
import { getRequest } from '../../../services/apiUtils';
import { Alert } from '../../../utils/toastr';
import ReactSelect from '../../../components/form/ReactSelect';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { Grid } from '@mui/material';
import {
    locationLevels,
    firstDataOrResetData,
} from '../../../redux/actions/createUser/create';
import { connect } from 'react-redux';

const defaultAll = {};
/**
 *GeoLevelDropdown reusable component
 * @param param0
 * @returns
 */

const UserGeoLevelDropdown = (props: any) => {
    const [allGeoLevels, setAllGeoLevels] = useState<any[]>([]);

    let lsData: any = getLocalStorageData('userData');

    let loggeduser = JSON.parse(lsData);

    const specificPersona = ['FS', 'PO'];

    //Initial rendering api call
    useEffect(() => {
        if (props.locationMapping.allgeolevels.length === 0) {
            getGeoLevelsFirst();
        }
    }, []);

    useEffect(() => {
        if (props.loadDropDown) {
            getGeoLevelsFirst();
            props.setLoadDropDown(false);
        }
    }, [props.countryCodeSelected]);

    useEffect(() => {
        if (props.reload) {
            getGeoLevelsFirst();
            props.setReload(false);
        }
    }, [props.reload]);

    /**
     * Get all three levels of geolevel
     * @param isCondUpdate (param is getting false value update initial data in first time)
     */
    const getGeoLevelsFirst = (isCondUpdate?: boolean) => {
        let obj: any = {
            countrycode:
                loggeduser?.roleid === 'SUPER_ADMIN'
                    ? props.countryCodeSelected
                    : loggeduser?.countrycode,
            lob:
                loggeduser?.roleid === 'SUPER_ADMIN'
                    ? props.lobSelected
                    : loggeduser?.lineofbusiness,
        };

        if (
            loggeduser.roleid !== 'ADMIN' &&
            loggeduser.roleid !== 'SUPER_ADMIN'
        ) {
            obj = {
                ...obj,
                userid: loggeduser?.userid,
                roleid: loggeduser.roleid,
            };
        }

        getRequest({
            url: 'lookup/geolevel123',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    const data = response?.body?.data;
                    props.firstDataOrResetData({
                        type: 'firstData',
                        data: [...data],
                    });
                    // setFirstData(data);

                    let firstLevel: any = {
                        label: '',

                        options: [],
                        selectedValue: '',
                        name: 'geolevel1code',
                    };
                    let secondLevel: any = {
                        label: '',

                        options: [],
                        selectedValue: '',
                        name: 'geolevel2code',
                    };
                    let thirdLevel: any = {
                        label: '',

                        options: [],
                        selectedValue: '',
                        name: 'geolevel3code',
                    };
                    let fourthLevel: any = {
                        label: 'Village',

                        options: [],
                        selectedValue: '',
                        name: 'geolevel4code',
                    };

                    let locationhierlevel1codes: any = [];
                    let locationhierlevel2codes: any = [];
                    let locationhierlevel3codes: any = [];

                    data?.length > 0 &&
                        data.forEach((level: any) => {
                            // if (level?.locationhierlevel1code) {
                            let bool1 = locationhierlevel1codes.includes(
                                level?.locationhierlevel1code
                            );
                            if (level?.locationhierlevel1code && !bool1) {
                                let first = {
                                    value: level?.locationhierlevel1code, //01 or 02
                                    label: level?.locationhierlevel1desc, //HP or Telan
                                    title: level?.locationhierlevel1name, //state
                                };

                                firstLevel = {
                                    ...firstLevel, //label,options,selectedvalue,name
                                    label: level?.locationhierlevel1name, //state
                                    options: [
                                        ...firstLevel.options, //Now has 2 objects
                                        { ...first },
                                    ],
                                };
                                locationhierlevel1codes.push(
                                    level?.locationhierlevel1code
                                );
                            }
                            let bool2 = locationhierlevel2codes.includes(
                                level?.locationhierlevel2code
                            );
                            if (level?.locationhierlevel2code && !bool2) {
                                //dist code
                                let second = {
                                    value: level?.locationhierlevel2code, //dist code
                                    label: level?.locationhierlevel2desc, //dist name
                                    title: level?.locationhierlevel2name, //Dist
                                };
                                secondLevel = {
                                    ...secondLevel,
                                    label: level?.locationhierlevel2name, //Dist
                                    options: [
                                        ...secondLevel.options,
                                        { ...second },
                                    ],
                                };
                                locationhierlevel2codes.push(
                                    level?.locationhierlevel2code
                                );
                            }
                            let bool3 = locationhierlevel3codes.includes(
                                level?.locationhierlevel3code
                            );
                            if (level?.locationhierlevel3code && !bool3) {
                                let third = {
                                    value: level?.locationhierlevel3code,
                                    label: level?.locationhierlevel3desc,
                                    title: level?.locationhierlevel3name, //Sub_dist
                                };
                                thirdLevel = {
                                    ...thirdLevel,
                                    label: level?.locationhierlevel3name,
                                    options: [
                                        ...thirdLevel.options,
                                        { ...third },
                                    ],
                                };
                                locationhierlevel3codes.push(
                                    level?.locationhierlevel3code
                                );
                            }
                        });

                    const mergedData: any = [
                        firstLevel,
                        secondLevel,
                        thirdLevel,
                        fourthLevel,
                    ];

                    if (!isCondUpdate) {
                        const advanceAllGeoLevels = mergedData.map(
                            (level: any) => {
                                return level.options.length === 1
                                    ? {
                                          ...level,
                                          options: [level.options[0]],
                                          selectedValue: level.options[0].value,
                                      }
                                    : level;
                            }
                        );
                        props.locationLevelsProvided(advanceAllGeoLevels);
                        props.firstDataOrResetData({
                            type: 'resetData',
                            data: advanceAllGeoLevels,
                        });
                        // setAllGeoLevels(advanceAllGeoLevels);

                        let result: boolean = false;
                        for (
                            let i = 0;
                            i < advanceAllGeoLevels.length - 1;
                            i++
                        ) {
                            if (advanceAllGeoLevels[i].options.length > 1) {
                                result = true;
                                break;
                            }
                        }
                        if (!result && obj.countrycode !== 'TH') {
                            getGeoLevelLast(
                                `${advanceAllGeoLevels[0].selectedValue},${advanceAllGeoLevels[1].selectedValue},${advanceAllGeoLevels[2].selectedValue}`,
                                advanceAllGeoLevels
                            );
                        }
                    }
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    /**
     * Get all geo level of three and four only
     * @param level
     * @param geoLevel
     */

    const getGeoLevelLast = (level: string, geoLevel: any[]) => {
        let obj: any = {
            level,
            countrycode:
                loggeduser?.roleid === 'SUPER_ADMIN'
                    ? props.countryCodeSelected
                    : loggeduser?.countrycode,
            lob:
                loggeduser?.roleid === 'SUPER_ADMIN'
                    ? props.lobSelected
                    : loggeduser?.lineofbusiness,
        };
        if (specificPersona.includes(loggeduser?.roleid)) {
            obj['userid'] = loggeduser.userid;
            obj['roleid'] = loggeduser.roleid;
        }
        getRequest({
            url: 'lookup/geolevel45',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data?.length > 0) {
                    const data = response?.body?.data;
                    // setLastData(data);
                    let fourthLevel: any = {
                        label: 'Village',
                        options: [],
                        selectedValue: '',
                        name: 'geolevel4code',
                    };
                    data?.length > 0 &&
                        data.forEach((level: any) => {
                            if (level?.locationhierlevel4code) {
                                let fourth = {
                                    value: level?.locationhierlevel4code, //village code
                                    label: level?.locationhierlevel4desc, //village name
                                    title: level?.locationhierlevel4name, //village
                                };
                                fourthLevel = {
                                    ...fourthLevel,
                                    label: level?.locationhierlevel4name,
                                    options: [
                                        ...fourthLevel.options,
                                        { ...fourth },
                                    ],
                                };
                            }
                        });
                    fourthLevel.options.length === 1 &&
                        (fourthLevel = {
                            ...fourthLevel,
                            selectedValue: fourthLevel.options[0].value,
                        });
                    const updatedData =
                        geoLevel?.length > 0
                            ? geoLevel.map((value: any) => {
                                  if (value?.name === 'geolevel4code') {
                                      return (value = {
                                          ...value,
                                          ...fourthLevel,
                                      });
                                  }
                                  return value;
                              })
                            : [];
                    // setAllGeoLevels([...updatedData]);
                    props.locationLevelsProvided(updatedData);
                    props.firstDataOrResetData({
                        type: 'resetData',
                        data: updatedData,
                    });
                } else {
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };
    /**
     * Handle geolevel dropdown options list
     * @param value
     * @param name
     * @param index
     */
    const handleDynamicOptions = (value: any, name: any, index: number) => {
        const geoLevelName: any = findGeoLevelName(name);
        if (name === 'geolevel3code') {
            if (value === 'ALL') {
                const updatedAllList: any[] = setNextLevelAll(
                    geoLevelName.currentGeo
                );
                setAllGeoLevels([...updatedAllList]);
            } else {
                const updatedData =
                    props.locationMapping.allgeolevels?.length > 0
                        ? props.locationMapping.allgeolevels.map(
                              (level: any) => {
                                  if (level?.name === geoLevelName.currentGeo) {
                                      //   return (level = {
                                      return {
                                          ...level,
                                          selectedValue: value,
                                      };
                                      //   });
                                  } else {
                                      return level;
                                  }
                              }
                          )
                        : [];
                props.locationLevelsProvided(updatedData);
                setAllGeoLevels(updatedData);

                let dummyArr = [];
                for (let i = 0; i < updatedData.length - 1; i++) {
                    dummyArr.push(updatedData[i].selectedValue);
                }

                const threeLevel = dummyArr.join(',');

                if (loggeduser?.countrycode !== 'TH') {
                    getGeoLevelLast(threeLevel, updatedData);
                }
            }
            // }
        } else {
            if (value === 'ALL') {
                const updatedAllList: any[] = setNextLevelAll(
                    geoLevelName.currentGeo
                );
                setAllGeoLevels([...updatedAllList]);
            } else {
                let condLevel: any = {
                    label: '',
                    // options: [defaultAll],
                    options: [],
                    selectedValue: 'ALL',
                    name: geoLevelName.name, //'geolevel2code' i.e. nextlevelcode
                };
                let defaultALL: any[] = [];
                let codes: any = [];

                props.locationMapping.firstData?.length > 0 &&
                    props.locationMapping.firstData.forEach((level: any) => {
                        let bool = codes.includes(
                            level?.[`${geoLevelName.second}code`]
                        );
                        if (level[geoLevelName.first] === value && !bool) {
                            //locationhierlevelcode  i.e. locationhierlevel1code
                            let obj = {
                                value: level?.[`${geoLevelName.second}code`], //locationhierlevel code of dist
                                label: level?.[`${geoLevelName.second}desc`], // dist name
                                title: level?.[`${geoLevelName.second}name`], // Dist
                            };
                            condLevel = {
                                ...condLevel,
                                label: obj.title,
                                options: [...condLevel.options, { ...obj }],
                            };
                            codes.push(obj.value);
                        }
                    });
                let secondLevelIndex = 0;
                // const lastIndex = allGeoLevels?.length - 1;
                // const lastLevel = allGeoLevels[lastIndex];
                const lastIndex =
                    props.locationMapping.allgeolevels?.length - 1;
                const lastLevel = props.locationMapping.allgeolevels[lastIndex];
                const updatedData =
                    props.locationMapping.allgeolevels?.length > 0
                        ? props.locationMapping.allgeolevels.map(
                              (level: any, i: any) => {
                                  if (
                                      geoLevelName.name !== lastLevel.name &&
                                      level?.name === geoLevelName.name
                                  ) {
                                      secondLevelIndex = i;
                                      // second level added
                                      return (level = {
                                          ...level,
                                          ...condLevel,
                                      });
                                  }
                                  // first level
                                  if (level?.name === geoLevelName.currentGeo) {
                                      return (level = {
                                          ...level,
                                          selectedValue: value,
                                      });
                                  }
                                  if (
                                      name !== lastLevel.name &&
                                      secondLevelIndex < i &&
                                      level.selectedValue !== 'ALL'
                                  ) {
                                      let obj = {
                                          ...level,
                                          selectedValue: 'ALL',
                                          options: [defaultAll],
                                      };
                                      return (level = { ...level, ...obj });
                                  }

                                  return level;
                              }
                          )
                        : [];
                props.locationLevelsProvided(updatedData);
                setAllGeoLevels(updatedData);
            }
        }
    };
    /**
     * This method is used to set default data for next level order
     * If getting value is ALL after next level of order set default value(defaultAll)
     * @param condGeoLevelName (Selected Geo Level name)
     * @returns
     */
    const setNextLevelAll = (condGeoLevelName: string) => {
        const objIndex = allGeoLevels.findIndex(
            (obj) => obj.name == condGeoLevelName
        );
        const list =
            allGeoLevels?.length > 0
                ? allGeoLevels.map((level: any, i: number) => {
                      if (objIndex === -1) {
                          return level;
                      } else if (objIndex < i) {
                          let obj = {
                              //for geol4
                              ...level,
                              selectedValue: 'ALL',
                              options: [defaultAll],
                          };
                          return (level = { ...level, ...obj });
                      } else {
                          if (condGeoLevelName === level.name) {
                              return (level = {
                                  ...level,
                                  selectedValue: 'ALL',
                              });
                          } else {
                              return level;
                          }
                      }
                  })
                : [];
        return list;
    };

    const findGeoLevelName = (value: string) => {
        switch (value) {
            case 'geolevel1code':
                return {
                    currentGeo: 'geolevel1code',
                    first: 'locationhierlevel1code',
                    second: 'locationhierlevel2',
                    name: 'geolevel2code',
                };
            case 'geolevel2code':
                return {
                    currentGeo: 'geolevel2code',
                    first: 'locationhierlevel2code',
                    second: 'locationhierlevel3',
                    name: 'geolevel3code',
                };
            case 'geolevel3code':
                return {
                    currentGeo: 'geolevel3code',
                    first: 'locationhierlevel3code',
                    second: 'locationhierlevel4',
                    name: 'geolevel4code',
                };
            case 'geolevel4code':
                return {
                    currentGeo: 'geolevel4code',
                    first: 'locationhierlevel4code',
                    second: 'locationhierlevel5',
                    name: 'geolevel5code',
                };
            case 'geolevel5code':
                return {
                    first: 'locationhierlevel5code',
                    second: 'locationhierlevel5',
                    name: 'geolevel6code',
                };
            default:
                return 'NA';
        }
    };

    const checkLabel = (label: any) => {
        let bool: boolean = false;
        for (let i = 0; i < label.length; i++) {
            if (label[i] === '_') {
                bool = true;
            }
        }
        if (bool) {
            const arr = label.split('_');

            const newArr = arr.map(
                (substr: any) =>
                    substr.charAt(0).toUpperCase() + substr.slice(1)
            );

            const correctLabel = newArr.join(' ');
            return correctLabel;
        } else {
            let correctLabel = label.charAt(0).toUpperCase() + label.slice(1);
            return correctLabel;
        }
    };

    return (
        <>
            {props.locationMapping.allgeolevels?.length > 0
                ? props.locationMapping.allgeolevels.map(
                      (list: any, i: number) => {
                          let countrycode =
                              loggeduser?.roleid === 'SUPER_ADMIN'
                                  ? props.countryCodeSelected
                                  : loggeduser?.countrycode;
                          if (countrycode !== 'TH') {
                              return (
                                  <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      key={list.name}>
                                      <div
                                          style={{
                                              color: '#696969',
                                              fontSize: '12px',
                                              fontFamily: 'appRegular',
                                              marginBottom: '4px',
                                          }}>
                                          {checkLabel(list.label)}
                                      </div>
                                      <ReactSelect
                                          name={list.name}
                                          value={list.selectedValue}
                                          handleChange={(
                                              selectedOptions: any,
                                              e: any
                                          ) => {
                                              handleDynamicOptions(
                                                  selectedOptions.value,
                                                  list.name,
                                                  i
                                              );
                                          }}
                                          options={list.options}
                                          defaultValue='ALL'
                                          id='prodGroup-test'
                                          dataTestId='prodGroup-test'
                                          placeHolder={`Select a ${checkLabel(
                                              list.label
                                          )}`}
                                      />
                                  </Grid>
                              );
                          } else {
                              if (
                                  countrycode === 'TH' &&
                                  list.name !== 'geolevel4code'
                              ) {
                                  return (
                                      <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          key={list.name}>
                                          <div
                                              style={{
                                                  color: '#696969',
                                                  fontSize: '12px',
                                                  fontFamily: 'appRegular',
                                                  marginBottom: '4px',
                                              }}>
                                              {checkLabel(list.label)}
                                          </div>
                                          <ReactSelect
                                              name={list.name}
                                              value={list.selectedValue}
                                              handleChange={(
                                                  selectedOptions: any,
                                                  e: any
                                              ) => {
                                                  handleDynamicOptions(
                                                      selectedOptions.value,
                                                      list.name,
                                                      i
                                                  );
                                              }}
                                              options={list.options}
                                              defaultValue='ALL'
                                              id='prodGroup-test'
                                              dataTestId='prodGroup-test'
                                              placeHolder={`Select a ${checkLabel(
                                                  list.label
                                              )}`}
                                          />
                                      </Grid>
                                  );
                              }
                          }
                      }
                  )
                : null}
        </>
    );
};

const mapStateToProps = ({ createUser: { locationMapping } }: any) => ({
    locationMapping,
});

const mapDispatchToProps = {
    locationLevelsProvided: locationLevels,
    firstDataOrResetData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserGeoLevelDropdown);
