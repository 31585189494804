/** @format */
import React, { useState } from 'react';
import TopHeader from '../../components/layouts/TopHeader';
import { Box } from '@mui/material';
import StepperComponent from '../countryConfig/countryConfig/Stepper';
import BasicInfo from './basic-info/BasicInfo';
import Stageinfo from './crop-details/stageinfo';

const steps = ['Crops and Season', 'Stages and SubStages'];

const NewCrop = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [basicInfoNextButtonClicked, setBasicInfoNextButtonClicked] =
        useState(false);

    const handleNextPage = (page: any, nextButtonClicked = false) => {
        setActiveStep(page);
        if (nextButtonClicked) {
            setBasicInfoNextButtonClicked(true);
        }
    };

    const handlePrevPage = (page: any) => {
        setActiveStep(page);
    };


    return (
        <div>
            <TopHeader children={<h3>Create New Crop Calendar</h3>} />
            <Box>
                <StepperComponent
                    direction='horizontal'
                    steps={steps}
                    currentStepNumber={activeStep}
                    stepColor='#89D329'
                />
            </Box>
            {activeStep === 0 && <BasicInfo handleGoToNextPage={handleNextPage} />}
            {activeStep === 1 && <Stageinfo goBackOnePage={handlePrevPage}/> }
        </div>
    );
};

export default NewCrop;
