/** @format */

import { Box, Card, Modal, Tooltip } from '@mui/material';
import { useState } from 'react';
import './index.scss';
import _ from 'lodash';
import generateRandomColor from '../../../components/ui/HelperFunction';
import CloseIcon from '@mui/icons-material/Close';
import { cropName, renderSvg, svgIconName } from '../../../assets/img/imageExt';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { getLocalStorageData } from '../../../utils/useLocalStorage';

function CustomAdvisoryModal(props: any) {
    const dayjs = require('dayjs');

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    var utc = require('dayjs/plugin/utc');
    var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const [openCustomAdvisoryDetail, setOpenCustomAdvisoryDetail] =
        useState(false);

    const handleClosePopup = () => {
        setOpenCustomAdvisoryDetail(false);
    };

    const handleCardClick = () => setOpenCustomAdvisoryDetail(true);

    const renderCropImage = (cropi: string = 'corn') => {
        if (cropi === 'chilli') {
            return renderSvg(cropName.chilli, 25);
        } else if (cropi === 'rice') {
            return renderSvg(cropName.rice, 25);
        } else if (cropi === 'tomato') {
            return renderSvg(cropName.tomato, 25);
        } else if (cropi === 'corn') {
            return renderSvg(cropName.corn, 18);
        } else if (cropi === 'capsicum') {
            return renderSvg(cropName.capsicum, 20);
        }
    };

    function getBorderColorBasedOnStatus(messagesubtype: string) {
        var style = '';
        if (messagesubtype === 'DISEASE') {
            style = 'color-orange-border-advi';
        } else if (messagesubtype === 'PEST') {
            style = 'color-green-border-advi';
        } else if (messagesubtype === 'RAINFALL') {
            style = 'color-blue-border-advi';
        } else {
            style = 'color-red-border-advi';
        }

        return style;
    }

    const getInitial = (name: string) => {
        let split = name.split(' ');
        const arr: any = split.map((item: string) => {
            return item.charAt(0).toUpperCase();
        });
        return arr.join('');
    };

    // function stringToColor(string: string, index: number) {
    //     var color = '';
    //     if (index === 0 || index === 2) {
    //         color = '#6A5ABC';
    //     } else {
    //         color = '#008CF8';
    //     }
    //     return color;
    // }

    function stringAvatar(name: string, index: number) {
        return {
            sx: {
                backgroundColor: generateRandomColor(),
                fontFamily: 'appMedium',
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function cardBorder() {
        if (
            props.item.scheduleddate &&
            dayjs(props.item.scheduleddate).tz(props.countryCode === 'TH'?'Asia/Bangkok':'Asia/Kolkata').format('DD MMM | LT') >
                dayjs().tz(props.countryCode === 'TH'?'Asia/Bangkok':'Asia/Kolkata').format('DD MMM | LT')
        ) {
            return 'card-size-advisory-green';
        }

        return 'card-size-advisory-orange';
    }

    return (
        <div>
            <Modal open={openCustomAdvisoryDetail}>
                <Box className='box-model'>
                    <div className='custom-advisory-modal-container'>
                        <div className='advisory-close-icon'>
                            <span className='custom_advisory_modal_header'>
                                Custom Advisory
                            </span>
                            <div>
                                <CloseIcon
                                    className='advisory_close_icon'
                                    onClick={handleClosePopup}
                                />
                            </div>
                        </div>
                        <div className='custom_advisory_body_message'>
                            {props.item.message}
                        </div>
                        <div>
                            <div>
                                <div className='custom_advisory_body_list'>
                                    <div>
                                        List of Farmers (
                                        {props.item.farmers
                                            ? props.item.farmers.length
                                            : 0}
                                        )
                                    </div>
                                </div>
                            </div>
                            <div className='custom_advisory_farmer_list_container'>
                                {props.item.farmers &&
                                    props.item.farmers.map((farmer: any) => {
                                        return (
                                            <div
                                                className='custom_advisory_farmer_list_div'
                                                key={farmer.farmerid}>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            generateRandomColor(),
                                                    }}
                                                    className='custom_advisory_famer_list_acr'>
                                                    {getInitial(
                                                        farmer.firstname +
                                                            ' ' +
                                                            farmer.lastname
                                                    )}
                                                </span>
                                                <span className='custom_advisory_famer_list_detail'>
                                                    <Tooltip
                                                        title={
                                                            farmer.firstname +
                                                            ' ' +
                                                            farmer.lastname
                                                        }>
                                                        <div
                                                            style={{
                                                                display:
                                                                    'block',
                                                            }}>
                                                            <div
                                                                className='custom_advisory_famer_list_name'
                                                                title={
                                                                    farmer.firstname +
                                                                    ' ' +
                                                                    farmer.lastname
                                                                }>
                                                                {farmer.firstname +
                                                                    ' ' +
                                                                    farmer.lastname}
                                                            </div>
                                                            <div className='custom_advisory_farmer_list_title'>
                                                                Farmer
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className='custom_advisory_body_list1'>
                                    <div>
                                        List of Consultants (
                                        {props.item.consultants
                                            ? props.item.consultants.length
                                            : 0}
                                        )
                                    </div>
                                </div>
                            </div>
                            <div className='custom_advisory_farmer_list_container'>
                                {props.item.consultants &&
                                    props.item.consultants.map(
                                        (consultant: any) => {
                                            return (
                                                <div
                                                    className='custom_advisory_farmer_list_div'
                                                    key={consultant.userid}>
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                generateRandomColor(),
                                                        }}
                                                        className='custom_advisory_famer_list_acr'>
                                                        {getInitial(
                                                            consultant.firstname +
                                                                ' ' +
                                                                consultant.lastname
                                                        )}
                                                    </span>
                                                    <span className='custom_advisory_famer_list_detail'>
                                                        <Tooltip
                                                            title={
                                                                consultant.firstname +
                                                                ' ' +
                                                                consultant.lastname
                                                            }>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                }}>
                                                                <div
                                                                    className='custom_advisory_famer_list_name'
                                                                    title={
                                                                        consultant.firstname +
                                                                        ' ' +
                                                                        consultant.lastname
                                                                    }>
                                                                    {consultant.firstname +
                                                                        ' ' +
                                                                        consultant.lastname}
                                                                </div>
                                                                <div className='custom_advisory_farmer_list_title'>
                                                                    {
                                                                        consultant.roledescription
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Card
                className={'card-size-advisory ' + cardBorder()}
                onClick={handleCardClick}>
                <div className='cropicon-advisory'>
                    <div className='cropimage-advisory'>
                        {renderCropImage(props.item.cropname.toLowerCase())}
                    </div>
                    <div className='deviceserial-alignment'>
                        <div className='deviceserial-advisory'>
                            {props.item.deviceserialno}
                        </div>

                        <div
                            className={getBorderColorBasedOnStatus(
                                props.item.messagesubtype
                            )}>
                            {props.item.messagesubtype
                                ?.charAt(0)
                                .toUpperCase() +
                                props.item.messagesubtype
                                    ?.slice(1)
                                    .toLowerCase()}
                        </div>
                    </div>
                </div>
                <div className='avatargroup'>
                    <AvatarGroup max={3}>
                        {props.item.farmers &&
                            props.item.farmers.map(
                                (farmer: any, index: number) => {
                                    return (
                                        <Avatar
                                            key={farmer.farmerid}
                                            {...stringAvatar(
                                                farmer.firstname +
                                                    ' ' +
                                                    farmer.lastname,
                                                index
                                            )}
                                        />
                                    );
                                }
                            )}
                    </AvatarGroup>
                    <div className='Farmer_suffix'>
                        {props.item.farmers.length > 1
                            ? 'Farmers'
                            : props.item.farmers.length === 0
                            ? 'No Farmers'
                            : 'Farmer'}
                    </div>
                </div>
                <div className='message-advisory'>
                    <Tooltip title={props.item.message} arrow>
                        <p
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                            }}>
                            {props.item.message}
                        </p>
                    </Tooltip>
                </div>
                <Tooltip
                    title={props.countryCode=== 'TH' || props.item.geolevel0code === 'TH' ? `${props.item.geolevel3}, ${props.item.geolevel2}, ${props.item.geolevel1}`.replace(
                        /\w+/g,
                        _.capitalize
                    ) : `${props.item.geolevel4}, ${props.item.geolevel3}, ${props.item.geolevel2}, ${props.item.geolevel1}`.replace(
                        /\w+/g,
                        _.capitalize
                    ) }
                    arrow>
                    <div className='location-advisory'>
                        <div>
                            <img
                                className='map-advisory'
                                src={svgIconName.map_pin.source}
                                width={'12px'}
                                alt='location'
                            />
                        </div>
                        <div className='ellipsis-location'>
                            {props.countryCode === 'TH' || props.item.geolevel0code === 'TH'? `${props.item.geolevel3}, ${props.item.geolevel2}, ${props.item.geolevel1}`.replace(
                                /\w+/g,
                                _.capitalize
                            ) : `${props.item.geolevel4}, ${props.item.geolevel3}, ${props.item.geolevel2}, ${props.item.geolevel1}`.replace(
                                /\w+/g,
                                _.capitalize
                            )}
                           
                        </div>
                    </div>
                </Tooltip>
                <div className='date-advisory'>
                    {dayjs(props.item.createddate).tz(props.countryCode === 'TH'?'Asia/Bangkok':'Asia/Kolkata').format('DD MMM | LT')}
                </div>
                <div className='date-advisory'>
                    {props.item.scheduleddate && (
                        <div>
                            Scheduled date and time :
                            {dayjs(props.item.scheduleddate).format('DD MMM | LT')}
                        
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
}

export default CustomAdvisoryModal;
