/** @format */

import React, { useState } from 'react';
import {
    Box,
    Button,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    TextField,
    InputAdornment,
    Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './ChildModal.scss';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: 1,
    cursor: 'pointer',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function ChildModal(props: any) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const[error,setError] = useState<any>('');
    const [Dosageopen, setDosageOpen] = useState(false);
    const handleDosageClick = () => setDosageOpen(true);
    const handleDosageClose = () => {
        var regex = /^[0-9]*\.?[0-9]*$/;

       let testingreg = true;
       props.selectedProductList.forEach((prod:any)=>{
           if(!regex.test(prod.dosage))testingreg = false 
        })

      if (!testingreg) {
            setError('enter valid dosage')
            setDosageOpen(true);
        }else {
            setDosageOpen(false);
        }
        
        
    };

    function displayUpdatedNotification() {
        if (!props.reject && !props.acceptAlert) {
            return props.alertMessage;
        } else if (
            (props.reject || props.acceptAlert) &&
            props.productList.length > 0
        ) {
            return props.getSprayName();
        }
        return props.getSprayNameWithProductList();
    }

    return (
        <div>
            <div>
                <Button color='secondary' onClick={handleDosageClick} sx={{textTransform:'none'}}>
                    Edit Dosage
                </Button>

                <Modal open={Dosageopen}>
                    <Box sx={style}>
                        <div className='eror-msg-dosage'>{error}</div>
                        <TableContainer>
                            <Table>
                                <TableHead
                                    style={{
                                        backgroundColor: '#10384f',
                                        color: '#fff',
                                    }}>
                                    <TableRow>
                                        <StyledTableCell>
                                            Product
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Dosage
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {props.selectedProductList &&
                                        props.selectedProductList.map(
                                            (data: any) => (
                                                <TableRow>
                                                    <TableCell align='left'>
                                                        {data.productname}
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <TextField
                                                            variant='outlined'
                                                            placeholder='Dosage'
                                                            size='small'
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        g/ml per
                                                                        acre
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            value={data.dosage}
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                             setError('');
                                                                let tempProdArr = props.selectedProductList.map(
                                                                    (
                                                                        product: any
                                                                    ) => {
                                                                        return {
                                                                            productname:
                                                                                product.productname,
                                                                            dosage:
                                                                                data.productname ===
                                                                                product.productname
                                                                                    ? event
                                                                                          .target
                                                                                          .value
                                                                                    : product.dosage,
                                                                        };
                                                                    }
                                                                );

                                                                props.setSelectedProductList(
                                                                    tempProdArr
                                                                );
                                                                let temporaryArray = tempProdArr.map(
                                                                    (
                                                                        prod: any
                                                                    ) => {
                                                                        return (
                                                                            prod.productname +
                                                                            '-' +
                                                                            prod.dosage +
                                                                            ' g/ml per acre'
                                                                        );
                                                                    }
                                                                );
                                                                props.setProductList(
                                                                    temporaryArray
                                                                );
                                                                
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack
                            direction='row'
                            spacing={2}
                            alignItems='baseline'
                            pt={3}
                            justifyContent='center'>
                            <Button
                                className='child-close-style'
                                onClick={handleDosageClose}>
                                <CloseSharpIcon style={{ height: 15 }} />
                                close
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default ChildModal;
