/** @format */

import React from 'react';
import { TextField, Button } from '@mui/material';
import './stageinfo.scss';
import { Alert } from '../../../utils/toastr';

function NotificationMessage(props: any) {
    const [notificationMessage, setNotificationMessage] = React.useState<string>('');

    return (
        <div>
            <div className='createcrop-notif-text'>
                <div className='label-header-lang'>
                    {props.notification.language}
                </div>
                <TextField
                    className='textfield-createcrop'
                    placeholder='Enter Message'
                    type='text'
                    id='outlined-basic'
                    variant='outlined'
                    multiline
                    rows={4}
                    defaultValue={props.notification.value}
                    onChange={(event) => {
                        setNotificationMessage(event.target.value);
                    }}
                />
            </div>
            <div className='createcrop-modal-button'>
                <Button
                    variant='contained'
                    color='success'
                    onClick={() => {
                        let tempArray = props.notificationArray;
                        tempArray = tempArray.map((not: any) => {
                            if(not.language === props.notification.language){
                                return {
                                    language: not.language,
                                    value:notificationMessage
                                };
                            }
                             return {
                                language: not.language,
                                value:not.value
                             }
                        });

                        props.updateSubStageRow(
                            tempArray,
                            'notifications',
                            props.index
                        );

                        Alert('success','Notification saved')
                    }}>
                    Save
                </Button>
            </div>
        </div>
    );
}

export default NotificationMessage;
