/** @format */

import BayerLogoImg from '../../assets/img/logo/bayer_logo.svg';

import Avatar from '@mui/material/Avatar';

import DropdownArrow from '../../assets/img/svg/down-arrow/dropdown_arrow.svg';

import GravityLogo from '../../assets/img/logo/app_logo.svg';

import LogoutIcon from '../../assets/img/svg/drawer/logout.svg';

import { getLocalStorageData } from '../../utils/useLocalStorage';

import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';

import DialogActions from '@mui/material/DialogActions';

import DialogContentText from '@mui/material/DialogContentText';

import Dialog from '@mui/material/Dialog';

import Authorization from '../../utils/authorization';

import { renderSvg, svgIconName } from '../../assets/img/imageExt';

import { Button, Menu, MenuItem } from '@mui/material';

import ListItemIcon from '@mui/material/ListItemIcon';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import './Header.scss';

interface TopHeader {
    page?: string;

    children?: any;

    onClose?: any;

    onCountryClose?: any;
}

const TopHeader: React.FC<TopHeader> = ({
    children,

    page = '',

    onClose,

    onCountryClose,
}) => {

    let data: any = getLocalStorageData('userData');

    let loggedUser = JSON.parse(data);


    const history = useNavigate();

    const [openPopup, setOpenPopup] = useState(false);

    const [openConsent, setOpenConsent] = useState(false);

    const [openCountryConsent, setOpenCountryConsent] = useState(false);

    const [selectedLob, setSelectedLob] = useState<string>(
        loggedUser.lineofbusiness
    );

    const [selectedCountry, setSelectedCountry] = useState<string>(
        loggedUser.countrycode
    );

    const [show, setShow] = useState<boolean>(false);

    const handleLogout = () => {
        Authorization.logOut();

        history(`/`);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [anchorElCountry, setAnchorElCountry] = useState<null | HTMLElement>(
        null
    );

    const [open, setOpen] = useState<boolean>(false);

    // const open = Boolean(anchorEl);

    const openCountry = Boolean(anchorElCountry);


    const handleDropDown = (event: React.MouseEvent<HTMLElement>) => {

        setAnchorEl(event.currentTarget);

        setOpen(true);
    };

    const handleCountryDropDown = (event: React.MouseEvent<HTMLElement>) => {

        setAnchorElCountry(event.currentTarget);
    };

    const handleClose = (lob: any) => {
        setAnchorEl(null);

        setOpen(false);

        setOpenConsent(true);

        setSelectedLob(lob);

    };

    const handleCountryClose = (
        countryCode: any,
        countryname: any,
        event: React.MouseEvent<HTMLElement>
    ) => {

        setAnchorEl(event.currentTarget);

        setOpen(true);

        // setAnchorElCountry(null);

        setSelectedCountry(countryCode);
    };


    const handleCancel = () => {
        setOpenConsent(false);

        setSelectedCountry(loggedUser.countrycode);
    };

    return (
        <div className='top-header'>
            <div className='header-content'>
                {loggedUser?.roleid === 'DEV_ADMIN' && (
                    <div>
                        <div className='sidebar_logo_cc'>
                            <img
                                src={GravityLogo}
                                height='50'
                                alt='Gravity Logo'
                            />
                        </div>

                        <div className='header_text'>D-ADVISORY</div>
                    </div>
                )}

                <div className='header-title'>{children}</div>

                {/* <Internetspeed></Internetspeed> */}

                <div className='right'>
                    <div>
                        <div className='profile-info'>
                            <Avatar alt='User Profile' />

                            <div className='user-details'>
                                <div className='name'>
                                    {loggedUser?.firstname}{' '}
                                    {loggedUser?.lastname}
                                </div>

                                <div className='role'>
                                   

                                    <span>
                                    {loggedUser?.roleid !== 'SUPER_ADMIN' ?
                                    loggedUser?.lineofbusiness  + ' - ' 
                                         : ''}
                                        {loggedUser?.rolename}
                                    </span>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',

                                        gap: '22px',
                                    }}>
                                   

                                    {((loggedUser.roleid === 'ADMIN' &&
                                        loggedUser?.allowedlob &&
                                        loggedUser?.allowedlob.length > 1) ) &&
                                        page === 'Dashboard' && (
                                            <div style={{ cursor: 'pointer' }}>
                                                <img
                                                    src={DropdownArrow}
                                                    onClick={handleDropDown}
                                                    alt='menu-arrow'
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>

                            <Menu
                                id='demo-positioned-menu'
                                aria-labelledby='demo-positioned-button'
                                anchorEl={anchorElCountry}
                                onClose={() => setAnchorElCountry(null)}
                                open={openCountry}
                                anchorOrigin={{
                                    vertical: 'bottom',

                                    horizontal: 'left',
                                }}>
                                {loggedUser?.assignedcountries &&
                                    loggedUser?.assignedcountries.map(
                                        (value: any, index: number) => {
                                            if (
                                                loggedUser?.countrycode ===
                                                value.countrycode
                                            ) {
                                                return (
                                                    <MenuItem
                                                        key={value.countrycode}
                                                        style={{
                                                            fontSize: 14,

                                                            fontFamily:
                                                                'appMedium',

                                                            paddingTop: 20,
                                                        }}
                                                        onClick={(evt: any) =>
                                                            handleCountryClose(
                                                                value.countrycode,

                                                                value.country,

                                                                evt
                                                            )
                                                        }
                                                        disabled>
                                                        View as {value.country}
                                                    </MenuItem>
                                                );
                                            } else {
                                                return (
                                                    <MenuItem
                                                        key={value.countrycode}
                                                        style={{
                                                            fontSize: 14,

                                                            fontFamily:
                                                                'appMedium',

                                                            paddingTop: 20,
                                                        }}
                                                        onClick={(evt: any) =>
                                                            handleCountryClose(
                                                                value.countrycode,

                                                                value.country,

                                                                evt
                                                            )
                                                        }>
                                                        View as {value.country}
                                                    </MenuItem>
                                                );
                                            }
                                        }
                                    )}
                            </Menu>

                            {loggedUser.roleid === 'SUPER_ADMIN' ? (
                                <Menu
                                    id='demo-positioned-menu_sa'
                                    aria-labelledby='demo-positioned-button'
                                    anchorEl={anchorEl}
                                    onClose={() => {
                                        setAnchorEl(null);

                                        setOpen(false);

                                        !openConsent &&
                                            setSelectedCountry(
                                                loggedUser.countrycode
                                            );
                                    }}
                                    open={open}
                                    anchorOrigin={{
                                        vertical: 'bottom',

                                        horizontal: 'left',
                                    }}
                                    anchorPosition={{
                                        top: 200,

                                        left: 1000,
                                    }}>
                                    {loggedUser?.assignedcountries &&
                                        loggedUser?.assignedcountries.map(
                                            (value: any, index: number) =>
                                                value.countrycode ===
                                                    selectedCountry &&
                                                value?.lineofbusiness?.map(
                                                    (lob: any) => {
                                                        if (
                                                            loggedUser?.lineofbusiness ===
                                                                lob &&
                                                            loggedUser?.countrycode ===
                                                                selectedCountry
                                                        ) {
                                                            return (
                                                                <MenuItem
                                                                    key={lob}
                                                                    style={{
                                                                        fontSize: 14,

                                                                        fontFamily:
                                                                            'appMedium',

                                                                        paddingTop: 20,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            lob
                                                                        )
                                                                    }
                                                                    disabled>
                                                                    View as{' '}
                                                                    {lob}
                                                                </MenuItem>
                                                            );
                                                        } else {
                                                            return (
                                                                <MenuItem
                                                                    key={lob}
                                                                    style={{
                                                                        fontSize: 14,

                                                                        fontFamily:
                                                                            'appMedium',

                                                                        paddingTop: 20,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            lob
                                                                        )
                                                                    }>
                                                                    View as{' '}
                                                                    {lob}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    }
                                                )
                                        )}
                                </Menu>
                            ) : (
                                <Menu
                                    id='demo-positioned-menu'
                                    aria-labelledby='demo-positioned-button'
                                    anchorEl={anchorEl}
                                    onClose={() => {
                                        setAnchorEl(null);

                                        setOpen(false);
                                    }}
                                    open={open}
                                    anchorOrigin={{
                                        vertical: 'bottom',

                                        horizontal: 'left',
                                    }}>
                                    {loggedUser?.allowedlob &&
                                        loggedUser?.allowedlob.map(
                                            (value: string, index: number) => {
                                                if (
                                                    loggedUser?.lineofbusiness ===
                                                    value
                                                ) {
                                                    return (
                                                        <MenuItem
                                                            key={value}
                                                            style={{
                                                                fontSize: 14,

                                                                fontFamily:
                                                                    'appMedium',

                                                                paddingTop: 20,
                                                            }}
                                                            onClick={() =>
                                                                handleClose(
                                                                    value
                                                                )
                                                            }
                                                            disabled>
                                                            View as {value}
                                                        </MenuItem>
                                                    );
                                                } else {
                                                    return (
                                                        <MenuItem
                                                            key={value}
                                                            style={{
                                                                fontSize: 14,

                                                                fontFamily:
                                                                    'appMedium',

                                                                paddingTop: 20,
                                                            }}
                                                            onClick={() =>
                                                                handleClose(
                                                                    value
                                                                )
                                                            }>
                                                            View as {value}
                                                        </MenuItem>
                                                    );
                                                }
                                            }
                                        )}
                                </Menu>
                            )}

                            <Dialog open={openConsent}>
                                <div
                                    style={{
                                        display: 'flex',

                                        color: '#10384F',

                                        padding: 10,

                                        columnGap: 10,

                                        fontWeight: 700,

                                        alignItems: 'end',
                                    }}>
                                    <div>
                                        {renderSvg(
                                            svgIconName.manage_accounts,

                                            '30'
                                        )}
                                    </div>

                                    <div>
                                        <span>
                                            {loggedUser.roleid === 'SUPER_ADMIN'
                                                ? 'BU/Country Change'
                                                : 'BU Change'}
                                        </span>
                                    </div>
                                </div>

                                <DialogContent>
                                    <DialogContentText id='alert-dialog-description'>
                                        <Typography
                                            style={{
                                                fontFamily: 'appRegular',

                                                color: '#808080',

                                                paddingLeft: 28,

                                                paddingRight: 40,
                                            }}>
                                            {loggedUser.roleid === 'SUPER_ADMIN'
                                                ? `Would you like to change your




                                                    view as ${selectedCountry} - ${selectedLob}- Admin?`
                                                : `Would you like to change your




                                                    view as ${selectedLob}- Admin?`}
                                        </Typography>

                                        <Typography
                                            style={{
                                                fontFamily: 'appItalic',

                                                paddingTop: 12,

                                                color: '#A9A9A9',

                                                paddingLeft: 29,
                                            }}>
                                            Note - Any unsaved changes will be
                                            discarded when switched.
                                        </Typography>
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions style={{ columnGap: 19 }}>
                                    <Button
                                        style={{
                                            border: '1px solid #fd513b',

                                            color: '#fd513b',

                                            borderRadius: 6,

                                            paddingLeft: 19,

                                            paddingRight: 19,

                                            textTransform: 'none',
                                        }}
                                        onClick={handleCancel}>
                                        Cancel
                                    </Button>

                                    <Button
                                        style={{
                                            backgroundColor: '#7eb343',

                                            color: 'white',

                                            borderRadius: 6,

                                            paddingLeft: 19,

                                            paddingRight: 19,

                                            textTransform: 'none',
                                        }}
                                        onClick={() =>
                                            onClose(
                                                selectedLob,
                                                selectedCountry
                                            )
                                        }>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        {loggedUser.roleid === 'DEV_ADMIN' && (
                            <div style={{ cursor: 'pointer' }}>
                                <img
                                    src={DropdownArrow}
                                    onClick={() => setOpenPopup(!openPopup)}
                                    alt='menu-arrow'
                                />
                            </div>
                        )}
                    </div>

                    {openPopup && (
                        <div className='cc_logout_popup' onClick={handleLogout}>
                            <span className='cc_logout_text'>Log out</span>

                            <Button sx={{ marginTop: 'auto', minWidth: '0px' }}>
                                <ListItemIcon style={{ minWidth: '0px' }}>
                                    <img src={LogoutIcon} alt='LOGOUT ICON' />
                                </ListItemIcon>
                            </Button>
                        </div>
                    )}

                    <div>
                        <img
                            src={BayerLogoImg}
                            alt='Bayer Logo'
                            className='bayer-logo-head'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopHeader;
