/** @format */

import React, { useState } from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Divider } from '@mui/material';
import { getLocalStorageData } from '../../../utils/useLocalStorage';

const FromDateToDate = (props:any) => {

    const [fromValue, setFromValue] = useState<any>(null);
    const [toValue, setToValue] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const [minDate, setMinDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(2, 'year');
        return currentDate;
    });
    const [maxDate, setMaxDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs();
        return currentDate;
    });

 

    const handleFromDate = (newValue: Dayjs | null) => {
        
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);
        
        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setFromValue(newValue);
        setFromDate(dateFilterValue);
        props.handleFilter(dateFilterValue,'fromdate')
    };

    const handleToDate = (newValue: Dayjs | null) => {
        
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(newValue);
        
        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        setToValue(newValue);
        setToDate(dateFilterValue);
        props.handleFilter(dateFilterValue,'todate')
    };

    return (
        <div>
            <div className='row'>
                <div className='title-label'>Created Date</div>
                <div className='sowingDate_div'>
                    <span>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={props.filter.fromdate}
                                    minDate={minDate}
                                    maxDate={toValue ? toValue : maxDate}
                                    onChange={handleFromDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            type='tel'
                                            title='From date'
                                            placeholder='mm/dd/yyyy'
                                            helperText={null}
                                            sx={{ width: 206 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </span>
                    <span className='sowingDate_divider'>
                        <Divider
                            orientation='horizontal'
                            variant='middle'
                            flexItem
                            style={{
                                borderColor: '#1A1A1A',
                            }}
                        />
                    </span>
                    <span>
                        <div className='toDate_div'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={props.filter.todate}
                                    onChange={handleToDate}
                                    minDate={fromValue ? fromValue : minDate}
                                    maxDate={maxDate}
                                    views={['year', 'month', 'day']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            type='tel'
                                            title='To date'
                                            placeholder='mm/dd/yyyy'
                                            helperText={null}
                                            sx={{ width: 195 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FromDateToDate;
