/** @format */

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import {
    InputBase,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    Autocomplete,
    InputLabel,
    FormControl,
} from '@mui/material';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getLocalStorageData } from '../../../../utils/useLocalStorage';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { saveOrUpdateRequest } from '../../../../services/apiUtils';
import { Alert } from '../../../../utils/toastr';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles, createStyles } from '@material-ui/core/styles';

function createData(name: string, value: string) {
    return { name, value };
}

const subscriptionarray = ['SMS', 'WhatsApp'];
const forecastarray = ['Daily Forecast Alert', 'Yesterday Forecast Alert'];
const showPersona = ['TPL', 'TM_SAM', 'ADMIN', 'TM_PL', 'SUPER_ADMIN'];

const useStyles = makeStyles((theme) =>
    createStyles({
        textfield: {
            '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                border: 'white',
            },
        },
        menu: {
            fontFamily: 'appRegular',
        },
    })
);

/**
 *Farmer Info Functional Component
 * @param props
 * @returns
 */
const FarmerInfo: React.FC<any> = (props: any) => {
    console.log('prop', props.user.countrycode);

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    var user = props.user;
    let totalacerage = props.totalacre;
    const style = {
        padding: '2%',
    };

    const classes = useStyles();

    const [firstName, setFirstName] = useState(user.firstname);
    const [lastName, setLastName] = useState(user.lastname);
    const [status, setStatus] = useState<any>(user.status);
    const [phonenum, setPhoneNum] = useState<any>(user.phonenumber);
    const [gender, setGender] = useState<any>(user.gender);
    const [preflanguage, setPrefLanguage] = useState<any>(user.defaultlanguage);
    const [buttonText, setButtonText] = useState('edit details');
    const [flag, setFlag] = useState(true);
    const [farmerState, setFarmerState] = useState<any>(user.geolevel1code);
    const [farmerDistrict, setFarmerDistrict] = useState<any>(
        user.geolevel2code
    );
    const [farmerSubDistrict, setFramerSubDistrict] = useState<any>(
        user.geolevel3code
    );
    const [farmerVillage, setFarmerVillage] = useState<any>(user.geolevel4code);

    const [farmerStateDesc, setFarmerStateDesc] = useState<any>(user.geolevel1);
    const [farmerDistrictDesc, setFarmerDistrictDesc] = useState<any>(
        user.geolevel2
    );
    const [farmerSubDistrictDesc, setFarmerSubDistrictDesc] = useState<any>(
        user.geolevel3
    );
    const [farmerVillageDesc, setFarmerVillageDesc] = useState<any>(
        user.geolevel4
    );

    const [subscriptionArray, setSubscriptionArray] = useState<any[]>(() => {
        let subList: any[] = [];
        if (props.user.smssubscription) subList.push('SMS');
        if (props.user.whatsappsubscription) subList.push('WhatsApp');
        return subList;
    });

    const [forecastArray, setForecastArray] = useState<any[]>(() => {
        let foreList: any[] = [];
        if (props.user.dailyforecastalert)
            foreList.push('Daily Forecast Alert');
        if (props.user.yesterdayforecastalert)
            foreList.push('Yesterday Forecast Alert');
        return foreList;
    });

    const handleSubscriptionChange = (
        event: SelectChangeEvent<typeof subscriptionArray>
    ) => {
        const {
            target: { value },
        } = event;
        setSubscriptionArray(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleForecastChange = (
        event: SelectChangeEvent<typeof forecastArray>
    ) => {
        const {
            target: { value },
        } = event;
        setForecastArray(typeof value === 'string' ? value.split(',') : value);
    };

    const handleFirstNameChange = (event: any) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: any) => {
        setLastName(event.target.value);
    };

    const handlePhoneNumChange = (event: any) => {
        setPhoneNum(event.target.value);
    };
    const handleStatusChange = (event: any) => {
        setStatus(event.target.value);
    };
    const handleGenderChange = (event: any) => {
        setGender(event.target.value);
    };

    const handleLanguageChange = (event: any) => {
        setPrefLanguage(event.target.value);
    };

    const handleStateChange = (event: any) => {
        setFarmerState(event.target.value);
        setFarmerStateDesc(props.stateMap.get(event.target.value).stateDesc);
        setFarmerDistrict('select');
        setFramerSubDistrict('select');
        setFarmerVillage('select');
    };
    const handleDistrictChange = (event: any) => {
        setFarmerDistrict(event.target.value);
        let districtMap = props.stateDistrictMap?.get(farmerState);
        setFarmerDistrictDesc(
            districtMap?.get(event.target.value).districtDesc
        );
        setFramerSubDistrict('select');
        setFarmerVillage('select');
    };
    const handleSubDistrictChange = (event: any) => {
        setFramerSubDistrict(event.target.value);
        let subdistrictMap = props.districtSubDistrictMap?.get(farmerDistrict);
        setFarmerSubDistrictDesc(
            subdistrictMap?.get(event.target.value).subDistrictDesc
        );
        setFarmerVillage('select');
    };
    const handleVillageChange = (event: any) => {
        setFarmerVillage(event.target.value);
        let villageMap = props.subDistrictVillageMap.get(farmerSubDistrict);
        setFarmerVillageDesc(villageMap.get(event.target.value).villageDesc);
    };

    function toTitleCase(str: any) {
        let firstLetter = str.substr(0, 1).toUpperCase();
        let remainingLetter = str.substr(1).toLowerCase();
        return firstLetter + remainingLetter;
    }

    useEffect(() => {
        props.masterLookup();
    }, []);

    function getCropNames() {
        let crops = user?.cropsgrown.map((crop: any) => {
            return crop.cropname;
        });
        return crops.join(',');
    }

    const handleClick = () => {
        var mode = buttonText;

        var error = [];
        if (mode === 'save') {
            var reg = new RegExp('^[0-9]+$');
            var regex = new RegExp('^[a-zA-Z ]*$');

            if (
                loggedUser.countrycode === 'TH' && props.user.countrycode === 'TH' &&
                (!reg.test(phonenum.trim()) || phonenum.trim().length !== 9)
            ) {
                error.push('Invalid phonenumber');
            } else if (
                loggedUser.countrycode === 'IN' && 
                (!reg.test(phonenum.trim()) || phonenum.trim().length !== 10)
            ) {
                error.push('Invalid phonenumber');
            }

            if (gender.trim().length === 0) {
                error.push(' Please specify a  gender');
            }

            if (firstName.trim().length === 0) {
                error.push('First name cannot be empty');
            }
            if (lastName.trim().length === 0) {
                error.push('Last name cannot be empty');
            }
            if (!regex.test(firstName.trim())) {
                error.push('Enter valid first name');
            }
            if (!regex.test(lastName.trim())) {
                error.push('Enter valid last name');
            }

            if (farmerState === 'select') {
                error.push('State cannot be empty ');
            }
            if (farmerDistrict === 'select') {
                error.push('District cannot be empty ');
            }
            if (farmerSubDistrict === 'select') {
                error.push('Sub District cannot be empty ');
            }
            if (farmerVillage === 'select') {
                error.push('Village cannot be empty ');
            }

            if (error.length === 0) {
                let ob = {
                    state: farmerStateDesc,
                    district: farmerDistrictDesc,
                    subDistrict: farmerSubDistrictDesc,
                    village: farmerVillageDesc,
                };
                props.geolevelupdate(ob);

                var param = {
                    'countrycode': loggedUser.roleid === 'SUPER_ADMIN' ? props.user.countrycode : loggedUser?.countrycode,
                    'farmerid': user?.farmerid,
                    'firstname': toTitleCase(firstName),
                    'lastname': toTitleCase(lastName),
                    'phonenumber': phonenum,
                    'gender': toTitleCase(gender),
                    'address': 'delhi',
                    'geolevel0code': loggedUser.roleid === 'SUPER_ADMIN' ? props.user.countrycode : loggedUser?.countrycode,
                    'geolevel1code': farmerState,
                    'geolevel2code': farmerDistrict,
                    'geolevel3code': farmerSubDistrict,
                    'geolevel4code': props.user.countrycode === 'IN' ?  farmerVillage :'',
                    'geolevel5code': '2',
                    'status': status,
                    'defaultlanguage': toTitleCase(preflanguage),
                    'totalacerage': totalacerage,
                    'smssubscription': subscriptionArray.includes('SMS'),
                    'whatsappsubscription': subscriptionArray.includes(
                        'WhatsApp'
                    ),
                    'dailyforecastalert': forecastArray.includes(
                        'Daily Forecast Alert'
                    ),
                    'yesterdayforecastalert': forecastArray.includes(
                        'Yesterday Forecast Alert'
                    ),
                    'dataconsentreceived':
                        status.trim() === 'ACTIVE' ? true : false,
                    'privacyconsentreceived':
                        status.trim() === 'ACTIVE' ? true : false,
                    'cropsinfo': [],
                };

                saveOrUpdateRequest({
                    url: 'customer/update',
                    method: 'PUT',
                    data: param,
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            Alert('success', 'User Data is Saved');
                            props.setIsAPICall(true);
                        }
                    })

                    .catch((error: any) => {
                        Alert(
                            'error',
                            'Error in saving the data . Please contact adminstrator'
                        );
                    });
            }
        }
        if (error.length === 0) {
            setButtonText(buttonText === 'save' ? 'edit details' : 'save');
            setFlag(!flag);
        } else {
            Alert('error', error.join(' , '));
        }
    };

    return (
        <Box sx={style}>
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                className='stack-farmerlist'>
                <div>
                    <div className='farmer-heading-font'>FirstName</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={firstName}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <div className='farmer-heading-font'>LastName</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={lastName}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <div className='farmer-heading-font'>PhoneNumber</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                disabled
                                value={
                                    loggedUser.countrycode === 'TH'
                                        ? `${'+66' + '' + phonenum}`
                                        : `${'+91' + '' + phonenum}`
                                }
                            />
                        ) : (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                value={phonenum}
                                onChange={handlePhoneNumChange}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <div className='farmer-heading-font'>Status</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={status}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                value={status}
                                onChange={handleStatusChange}>
                                {props.farmerStatus &&
                                    props.farmerStatus.map((status: any) => (
                                        <MenuItem value={status}>
                                            {status}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </div>
                </div>
                <div>
                    <div className='farmer-heading-font'>Gender</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={gender}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                value={gender}
                                onChange={handleGenderChange}>
                                {props.gender &&
                                    props.gender.map((gend: any) => (
                                        <MenuItem value={gend}>{gend}</MenuItem>
                                    ))}
                            </Select>
                        )}
                    </div>
                </div>
            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                className='stack-farmerlist'>
                <div>
                    <div className='farmer-heading-font'>
                        Preferred Language
                    </div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={preflanguage}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                value={preflanguage}
                                onChange={handleLanguageChange}>
                                {props.language &&
                                    props.language.map((lang: any) => (
                                        <MenuItem value={lang}>{lang}</MenuItem>
                                    ))}
                            </Select>
                        )}
                    </div>
                </div>

                <div>
                    <div className='farmer-heading-font'>Channel Mode</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                disabled
                                value={
                                    subscriptionArray
                                        ? subscriptionArray.join(',')
                                        : ''
                                }
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                labelId='demo-multiple-checkbox-label'
                                id='demo-multiple-checkbox'
                                multiple
                                value={subscriptionArray}
                                onChange={handleSubscriptionChange}
                                input={<OutlinedInput label='Tag' />}
                                renderValue={(selected) =>
                                    selected ? selected.join(',') : ''
                                }>
                                {subscriptionarray.map((sub) => (
                                    <MenuItem
                                        key={sub}
                                        value={sub}
                                        className='menu-item-font'>
                                        <Checkbox
                                            checked={
                                                subscriptionArray.indexOf(sub) >
                                                -1
                                            }
                                        />
                                        <ListItemText primary={sub} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                </div>

                <div>
                    <div className='farmer-heading-font'>Forecast Report</div>

                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                disabled
                                value={
                                    forecastArray ? forecastArray.join(',') : ''
                                }
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                labelId='demo-multiple-checkbox-label'
                                id='demo-multiple-checkbox'
                                multiple
                                value={forecastArray}
                                onChange={handleForecastChange}
                                input={<OutlinedInput label='Tag' />}
                                renderValue={(selected) =>
                                    selected ? selected.join(',') : ''
                                }>
                                {forecastarray.map((fore) => (
                                    <MenuItem
                                        key={fore}
                                        value={fore}
                                        className={classes.menu}>
                                        <Checkbox
                                            checked={
                                                forecastArray.indexOf(fore) > -1
                                            }
                                        />
                                        <ListItemText primary={fore} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                </div>

                <div>
                    {loggedUser.countrycode === 'IN' &&
                    props.user.countrycode === 'IN' ? (
                        <div className='farmer-heading-font'>State</div>
                    ) : (
                        <div className='farmer-heading-font'>Province</div>
                    )}
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={farmerStateDesc}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                id='state-simple-select'
                                value={farmerState}
                                onChange={handleStateChange}>
                                <MenuItem
                                    value='select'
                                    disabled
                                    className='select'>
                                    <span className='select'>
                                        Select a State
                                    </span>
                                </MenuItem>
                                {props.stateMap &&
                                    Array.from(props.stateMap?.values()).map(
                                        (values: any) => {
                                            return (
                                                <MenuItem
                                                    className='menu-item-font'
                                                    value={values.stateCode}>
                                                    {values.stateDesc}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </Select>
                        )}
                    </div>
                </div>

                <div>
                    <div className='farmer-heading-font'>District</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={farmerDistrictDesc}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                id='district-simple-select'
                                value={farmerDistrict}
                                onChange={handleDistrictChange}>
                                <MenuItem value='select' disabled>
                                    <span className='select'>
                                        Select a District
                                    </span>
                                </MenuItem>
                                {props.stateDistrictMap &&
                                    props.stateDistrictMap?.has(farmerState) &&
                                    Array.from(
                                        props.stateDistrictMap
                                            ?.get(farmerState)
                                            ?.values()
                                    ).map((values: any) => {
                                        return (
                                            <MenuItem
                                                className='menu-item-font'
                                                value={values.districtCode}>
                                                {values.districtDesc}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        )}
                    </div>
                </div>
            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}>
                <div>
                    <div className='farmer-heading-font'>Sub District</div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled
                                value={farmerSubDistrictDesc}
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                            />
                        ) : (
                            <Select
                                className='farmer-user-type-width'
                                id='sub-district-simple-select'
                                value={farmerSubDistrict}
                                onChange={handleSubDistrictChange}>
                                <MenuItem value='select' disabled>
                                    <span className='select'>
                                        Select a Sub-District
                                    </span>
                                </MenuItem>
                                {props.districtSubDistrictMap &&
                                    props.districtSubDistrictMap.has(
                                        farmerDistrict
                                    ) &&
                                    Array.from(
                                        props.districtSubDistrictMap
                                            .get(farmerDistrict)
                                            .values()
                                    ).map((values: any) => {
                                        return (
                                            <MenuItem
                                                className='menu-item-font'
                                                value={values.subDistrictCode}>
                                                {values.subDistrictDesc}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        )}
                    </div>
                </div>

                {loggedUser.countrycode === 'IN' &&
                props.user.countrycode === 'IN' ? (
                    <div>
                        <div className='farmer-heading-font'>Village</div>
                        <div>
                            {buttonText === 'edit details' ? (
                                <TextField
                                    disabled
                                    value={farmerVillageDesc}
                                    sx={{
                                        input: {
                                            fontFamily: 'appRegular',
                                            fontSize: 'smaller',
                                        },
                                    }}
                                />
                            ) : (
                                <div>
                                    <Select
                                        className='farmer-user-type-width'
                                        id='village-simple-select'
                                        value={farmerVillage}
                                        onChange={handleVillageChange}>
                                        <MenuItem value='select' disabled>
                                            <span className='select'>
                                                Select a Village
                                            </span>
                                        </MenuItem>
                                        {props.subDistrictVillageMap &&
                                            props.subDistrictVillageMap.has(
                                                farmerSubDistrict
                                            ) &&
                                            Array.from(
                                                props.subDistrictVillageMap
                                                    .get(farmerSubDistrict)
                                                    .values()
                                            ).map((values: any) => {
                                                return (
                                                    <MenuItem
                                                        className='menu-item-font'
                                                        value={
                                                            values.villageCode
                                                        }>
                                                        {values.villageDesc}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <div>
                    <div className='farmer-heading-font'>Crops Grown</div>
                    {buttonText === 'edit details' ? (
                        <TextField
                            sx={{
                                input: {
                                    fontFamily: 'appRegular',
                                    fontSize: 'smaller',
                                },
                            }}
                            disabled
                            value={getCropNames()}
                        />
                    ) : (
                        <div>
                            <TextField
                                sx={{
                                    input: {
                                        fontFamily: 'appRegular',
                                        fontSize: 'smaller',
                                    },
                                }}
                                disabled
                                value={getCropNames()}></TextField>
                        </div>
                    )}
                </div>

                <div>
                    <div className='farmer-heading-font'>
                        Total Land Size (in acres)
                    </div>
                    <div>
                        {buttonText === 'edit details' ? (
                            <div>
                                <TextField
                                    disabled
                                    value={totalacerage}
                                    sx={{
                                        input: {
                                            fontFamily: 'appRegular',
                                            fontSize: 'smaller',
                                        },
                                    }}
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    disabled
                                    value={totalacerage}
                                    sx={{
                                        input: {
                                            fontFamily: 'appRegular',
                                            fontSize: 'smaller',
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {loggedUser.countrycode !== 'IN' ||
                props.user.countrycode !== 'IN' ? (
                    <>
                        <div>
                            <div className='farmer-heading-fonts'>testitem</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled
                                        className={classes.textfield}
                                        sx={{
                                            input: {
                                                fontFamily: 'appRegular',
                                                fontSize: 'smaller',
                                            },
                                        }}
                                    />
                                ) : (
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled
                                            className={classes.textfield}
                                            sx={{
                                                height: 35,
                                                fontFamily: 'appRegular',
                                                fontSize: 13,
                                                marginLeft: '-34px',
                                            }}
                                            id='district-simple-select'></TextField>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='farmer-heading-fonts'>testitem</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled
                                        className={classes.textfield}
                                        sx={{
                                            input: {
                                                fontFamily: 'appRegular',
                                                fontSize: 'smaller',
                                            },
                                        }}
                                    />
                                ) : (
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled
                                            className={classes.textfield}
                                            sx={{
                                                height: 35,
                                                fontFamily: 'appRegular',
                                                fontSize: 13,
                                                marginLeft: '-14px',
                                            }}
                                            id='district-simple-select'></TextField>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <div className='farmer-heading-fonts'>testitem</div>
                        <div>
                            {buttonText === 'edit details' ? (
                                <TextField
                                    disabled
                                    className={classes.textfield}
                                    sx={{
                                        input: {
                                            fontFamily: 'appRegular',
                                            fontSize: 'smaller',
                                            marginLeft: '1%',
                                        },
                                    }}
                                />
                            ) : (
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        className={classes.textfield}
                                        sx={{
                                            height: 35,
                                            fontFamily: 'appRegular',
                                            fontSize: 13,
                                            marginLeft: '-14px',
                                        }}
                                        id='district-simple-select'></TextField>
                                </FormControl>
                            )}
                        </div>
                    </div>
                )}
            </Stack>

            <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                className='stack-button-space'>
                <div>
                    {showPersona.includes(loggedUser.roleid.toUpperCase()) && (
                        <Button
                            onClick={handleClick}
                            variant='contained'
                            color={flag ? 'primary' : 'secondary'}>
                            {flag ? <EditIcon /> : <DoneIcon />}
                            &nbsp; {buttonText}
                        </Button>
                    )}
                </div>
            </Stack>
        </Box>
    );
};

export default FarmerInfo;
