/** @format */

import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Select,
    Autocomplete,
    TextField,
} from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Dayjs } from 'dayjs';

import { getLocalStorageData } from '../../../utils/useLocalStorage';
import './index.scss';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import TextAreaMessage from '@material-ui/core/TextField';
import { Alert } from '../../../utils/toastr';
import { getRequest, saveOrUpdateRequest } from '../../../services/apiUtils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

const dayjs = require('dayjs');

const utc = require('dayjs/plugin/utc');

const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(isSameOrBefore);

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            fontFamily: 'appRegular',
            fontSize: 'small',
        },
        textfield: {
            '& .MuiInputBase-input.MuiAutocomplete-input': {
                fontFamily: 'appMedium',
                fontSize: 'small',
                overFlowY: 'scroll',
            },

            '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
                color: 'black',
                fontFamily: 'appMedium',
            },
        },
        textfieldfarmer: {
            '& .MuiInputBase-input.MuiAutocomplete-input': {
                fontFamily: 'appMedium',
                fontSize: 'small',
            },

            '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
                color: 'black',
                fontFamily: 'appMedium',
            },
        },
    })
);

interface IModalCustom {
    open: any;
    setOpen: any;
    setMakeAPICall: any;
}

function ModalCustom({ open, setOpen, setMakeAPICall }: IModalCustom) {
    const classes = useStyles();

    const CHARACTER_LIMIT = 160;
    const [cropArray, setCropArray] = useState<any[]>([]);
    const [deviceArray, setDeviceArray] = useState<any[]>([]);
    const [locationArray, setLocationArray] = useState<any[]>([]);
    const [farmerArray, setFarmerArray] = useState<any[]>([]);

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [cropKey, setCropKey] = useState<any>(Math.random());
    const [deviceKey, setDeviceKey] = useState<any>(Math.random());
    const [villageKey, setVillageKey] = useState<any>(Math.random());
    const [farmerKey, setFarmerKey] = useState<any>(Math.random());
    const [alertKey, setAlertKey] = useState<any>(Math.random());

    const [selectedAlert, setSelectedAlert] = useState<string>('');

    const [selectedCrop, setSelectedCrop] = useState<any>('0');
    const [selectedDevice, setSelectedDevice] = useState<any>('0');
    const [selectedVillage, setSelectedVillage] = useState<any>('0');
    const [selectedFarmer, setSelectedFarmer] = useState<any>([]);
    const [selectedFarmerOptions, setSelectedFarmerOptions] = useState<any>([]);
    const [message, setMessage] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>();

    const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(null);

    const [selectedCountry, setSelectedCountry] = useState<string>(
        loggedUser?.countrycode
    );
    const [selectedLob, setSelectedLob] = useState<string>(
        loggedUser.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser.lineofbusiness
    );

    const [countryData, setCountryData] = useState<any[]>([]);
    const [lobData, setLobData] = useState<any[]>([]);

    const handleClosePopup = () => {
        setOpen(false);
        setFarmerKey(Math.random());
        setCropKey(Math.random());
        setDeviceKey(Math.random());
        setVillageKey(Math.random());
        setAlertKey(Math.random());
        setSelectedCountry(loggedUser?.countrycode);
        getLobData(loggedUser?.countrycode);
        setSelectedCrop('0');
        setSelectedDevice('0');
        setSelectedVillage('0');
       
        setSelectedAlert('');
        setScheduledDate(null);
        setMessage('');
        setSelectedLob(
            loggedUser.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser.lineofbusiness
        );
        setLocationArray([]);
        setDeviceArray([]);
        setFarmerArray([]);
        setSelectedFarmer([]);
        setSelectedFarmerOptions([]);
        setErrorMsg(''); 
    };

    const handleCreateClick = () => {
        if (!validateRequest()) {
            setErrorMsg('');
            saveCustomAdvisoryData();
            setSelectedLob(
                loggedUser.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser.lineofbusiness
            );
        }
    };

    const handleAlertChange = (event: any) => {
        setSelectedAlert(event.target.value);
    };

    const handleOnCropClick = (event: any, value: any, reason: string) => {
        if (value === null) {
            setSelectedCrop('0');
            getCustomAdvisoryDataByfeilds('0', '', '', 'device');
        } else {
            setSelectedCrop(value.cropid);
            getCustomAdvisoryDataByfeilds(value.cropid, '', '', 'device');
        }
        setDeviceKey(Math.random());
        setVillageKey(Math.random());
        setFarmerKey(Math.random());
        setLocationArray([]);
        setDeviceArray([]);
        setSelectedFarmerOptions([]);
        setFarmerArray([]);
    };

    const handleOnDeviceClick = (event: any, value: any, reason: string) => {
        if (value === null) {
            setSelectedDevice('0');
            getCustomAdvisoryDataByfeilds(selectedCrop, '0', '', 'location');
        } else {
            setSelectedDevice(value.deviceid);
            getCustomAdvisoryDataByfeilds(
                selectedCrop,
                value.deviceid,
                '',
                'location'
            );
        }

        setVillageKey(Math.random());
        setFarmerKey(Math.random());
    };

    const handleOnVillageClick = (event: any, value: any, reason: string) => {
        if (value === null) {
            setSelectedVillage('0');
            getCustomAdvisoryDataByfeilds(
                selectedCrop,
                selectedDevice,
                '0',
                'farmer'
            );
        } else {
            setSelectedVillage(value.geolevel4code);
            getCustomAdvisoryDataByfeilds(
                selectedCrop,
                selectedDevice,
                value.geolevel4code,
                'farmer'
            );
        }

        setFarmerKey(Math.random());
    };

    const handleFarmerOnClick = (event: any, value: any, reason: string) => {
        if (value === null || value.length === 0) {
            setSelectedFarmer([]);
            setSelectedFarmerOptions([]);
        } else if (value.length > 0 && value[0].farmerid === 'ALL') {
            setSelectedFarmer(
                farmerArray.filter((value: any) => {
                    return value.farmerid !== 'ALL';
                })
            );

            setSelectedFarmerOptions(value);
        } else {
            if (value.some((farmer: any) => farmer.farmerid === 'ALL')) {
                setSelectedFarmerOptions(
                    farmerArray.filter((value: any) => {
                        return value.farmerid === 'ALL';
                    })
                );
            } else {
                setSelectedFarmerOptions(value);
            }
            setSelectedFarmer(value);
        }
    };

    const handleChange = () => (event: any) => {
        setMessage(event.target.value);
    };

    const handleReset = (event: any) => {
        setFarmerKey(Math.random());
        setCropKey(Math.random());
        setDeviceKey(Math.random());
        setVillageKey(Math.random());
        setAlertKey(Math.random());

        setSelectedCrop('0');
        setSelectedDevice('0');
        setSelectedVillage('0');
        setSelectedCountry(loggedUser?.countrycode);
        getLobData(loggedUser?.countrycode);
        setSelectedAlert('');
        setScheduledDate(null);
        setMessage('');
        setSelectedLob(
            loggedUser.roleid === 'SUPER_ADMIN'
                ? 'ALL'
                : loggedUser.lineofbusiness
        );
        setLocationArray([]);
        setDeviceArray([]);
        setFarmerArray([]);
        setSelectedFarmer([]);
        setSelectedFarmerOptions([]);
        setErrorMsg('');        
    };

    const validateRequest = () => {
        let errMsg = [];

        const timeZone =
            loggedUser?.countrycode === 'TH' ? 'Asia/Bangkok' : 'Asia/Kolkata';
        const currentTime = dayjs().tz(timeZone);
        const st = dayjs(scheduledDate).tz(timeZone, true);

        if (loggedUser.roleid === 'SUPER_ADMIN' && selectedLob === 'ALL') {
            errMsg.push('Please select Business Unit');
        }

        if (st.isSameOrBefore(currentTime)) {
            errMsg.push('Schedule date should be after current date and time');
        }

        if (message === '') {
            errMsg.push('Message should not be empty');
        }

        if (selectedCrop === 0) {
            errMsg.push('Please select a crop');
        }

        if (selectedDevice === 0) {
            errMsg.push('Please select a device');
        }

        if (selectedVillage === 0) {
            errMsg.push('Please select a village');
        }

        if (selectedFarmer.length === 0) {
            errMsg.push('Please select one or more farmer');
        }

        if (selectedAlert === '') {
            errMsg.push('Please select AlertType');
        }

        if (errMsg.length > 0) {
            let errors = '';
            errMsg.forEach((value: any, index: any) => {
                if (index === errMsg.length - 1) {
                    errors += value;
                } else {
                    errors += value + ',';
                }
            });
            setErrorMsg(errors);
            return true;
        }

        return false;
    };

    useEffect(() => {
        getLookup();
        getCreateCustomAdvisoryData();
    }, []);


    const getLookup = () => {
        console.log('logmaster')
        let obj: any = {
            countrycode: selectedCountry,
            lob: selectedLob,
        };
        // setLoader(true);
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setCountryData(response?.body?.data?.countries);
                    setLobData(response?.body?.data?.lineofbusiness);
                }
            })
            .catch((error: any) => {
                Alert('error', 'Error in getting lookup data');
                setCountryData([]);
                setLobData([]);
            });
    };

    const getLobData = (countryPassed: any) => {
        console.log('masterrr')
        let obj: any = {
            countrycode: countryPassed,
            lob: 'ALL',
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setLobData(response?.body?.data?.lineofbusiness);
                }
            })
            .catch((error: any) => {
                Alert('error', 'Error in getting lob data');
                setLobData([]);
            });
    };

    function getCustomAdvisoryDataByfeilds(
        cropId: any,
        deviceId: any,
        geolevel4Code: any,
        returnType: any
    ) {
        let requestBody = {
            countrycode: selectedCountry,
            lob: selectedLob,
            loggeduserrole: loggedUser?.roleid,
            userid: loggedUser?.userid,
        };

        if (cropId !== '') {
            requestBody = {
                ...requestBody,
                ...{ cropid: cropId },
            };
        }

        if (deviceId !== '') {
            requestBody = {
                ...requestBody,
                ...{ deviceid: deviceId },
            };
        }

        if (geolevel4Code !== '' && selectedCountry !== 'TH') {
            requestBody = {
                ...requestBody,
                ...{ geolevel4code: geolevel4Code },
            };
        }

        if (geolevel4Code !== '' && selectedCountry === 'TH') {
            requestBody = {
                ...requestBody,
                ...{ geolevel3code: geolevel4Code },
            };
        }

        getRequest({
            url: 'lookup/customadvisory',
            method: 'GET',
            queryParams: requestBody,
        })
            .then((response: any) => {
                if (returnType === 'device') {
                    let tempDeviceArray: any[] = [];
                    response.body.data.devices.forEach((device: any) => {
                        tempDeviceArray.push({
                            deviceid: device.deviceid,
                            deviceserialno: device.deviceserialno,
                        });
                    });
                    setDeviceArray(tempDeviceArray);
                }

                if (returnType === 'location' && selectedCountry !== 'TH') {
                    let tempLocationArray: any[] = [];
                    response.body.data.villages.forEach((location: any) => {
                        tempLocationArray.push({
                            geolevel4code: location.geolevel4code,
                            locationhierlevel4desc:
                                location.locationhierlevel4desc,
                        });
                    });

                    setLocationArray(tempLocationArray);
                }

                if (returnType === 'location' && selectedCountry === 'TH') {
                    let tempLocationArray: any[] = [];
                    response.body.data.villages.forEach((location: any) => {
                        tempLocationArray.push({
                            geolevel4code: location.geolevel3code,
                            locationhierlevel4desc:
                                location.locationhierlevel3desc,
                        });
                    });

                    setLocationArray(tempLocationArray);
                }

                if (returnType === 'farmer') {
                    let tempFarmerArray: any[] = [];

                    if (response.body.data.farmers.length > 0) {
                        tempFarmerArray.push({
                            farmerid: 'ALL',
                            firstname: 'All',
                            lastname: 'Farmers',
                        });
                    }

                    response.body.data.farmers.forEach((farmer: any) => {
                        tempFarmerArray.push({
                            farmerid: farmer.farmerid,
                            firstname: farmer.firstname,
                            lastname: farmer.lastname,
                        });
                    });

                    setFarmerArray(tempFarmerArray);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    }

    const saveCustomAdvisoryData = () => {
        let customAdvisoryRequest = {
            countrycode: selectedCountry,
            lineofbusiness: selectedLob,
            cropid: selectedCrop,
            deviceid: selectedDevice,
            farmers: [...selectedFarmer],
            message: message,
            alerttype: selectedAlert,
        };

        if (scheduledDate) {
            customAdvisoryRequest = {
                ...customAdvisoryRequest,
                ...{ scheduleddate: scheduledDate },
            };
        }

        if (selectedVillage && selectedCountry !== 'TH') {
            customAdvisoryRequest = {
                ...customAdvisoryRequest,
                ...{ geolevel4code: selectedVillage },
            };
        }

        if (selectedVillage && selectedCountry === 'TH') {
            customAdvisoryRequest = {
                ...customAdvisoryRequest,
                ...{ geolevel3code: selectedVillage },
            };
        }

        saveOrUpdateRequest({
            url: 'advisory/create',
            method: 'POST',
            data: customAdvisoryRequest,
        })
            .then((response: any) => {
                console.log('cus', response);


                setFarmerKey(Math.random());
                setCropKey(Math.random());
                setDeviceKey(Math.random());
                setVillageKey(Math.random());
                setAlertKey(Math.random());
                setSelectedCountry(loggedUser?.countrycode);
                getLobData(loggedUser?.countrycode);
                setScheduledDate(null);
                setSelectedCrop('0');
                setSelectedDevice('0');
                setSelectedVillage('0');

                setMessage('');
                setSelectedFarmer([]);
                setSelectedFarmerOptions([]);
                setSelectedAlert('');
                setMakeAPICall(true);
                setOpen(false);

            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    const getCreateCustomAdvisoryData = () => {
        console.log('create')
        let obj: any = {
            countrycode: selectedCountry,
            lob: selectedLob,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    const cropsList = response?.body?.data?.croplist;
                    let tempCropArray: any[] = [];
                    cropsList.forEach((crop: any) => {
                        tempCropArray.push({
                            cropid: crop.cropid,
                            cropname: crop.cropname,
                        });
                    });
                    setCropArray(tempCropArray);
                }
            })
            .catch((error: any) => {
                Alert('error', error?.message);
            });
    };

    return (
        <div>
            <Modal open={open}>
                <Box className='boxstyle'>
                    <div className='closeheading-advisory'>
                        <div className='customheading-advisory'>
                            Custom Advisory Message
                        </div>
                        <div>
                            <CloseIcon
                                className='advisory_close_icon'
                                onClick={handleClosePopup}
                            />
                        </div>
                    </div>
                    <div>
                        <span
                            style={{
                                color: 'red',
                                fontFamily: 'appRegular',
                                fontSize: 13,
                                paddingBottom: 4,
                            }}>
                            {errorMsg}
                        </span>
                    </div>

                    <div>
                        <span
                            style={{
                                color: 'red',
                                fontFamily: 'appRegular',
                                fontSize: 13,
                                paddingBottom: 4,
                            }}></span>
                    </div>
                    <div className='advisory-scroll'>
                        {loggedUser.roleid === 'SUPER_ADMIN' ? (
                            <div className='create-custom-advisory-row1'>
                                <div className='crop-container'>
                                    <div className='row-name'>Country</div>
                                    <div className='boxtext-div'>
                                        <Select
                                            value={selectedCountry}
                                            className='alert-style'
                                            sx={{
                                                width: '100%',
                                                fontFamily: 'appRegular',
                                                fontSize: 10,
                                            }}
                                            onChange={(event: any) => {
                                                setSelectedCountry(
                                                    event?.target.value
                                                );
                                                getLobData(event?.target.value);
                                                setFarmerKey(Math.random());
                                                setCropKey(Math.random());
                                                setDeviceKey(Math.random());
                                                setVillageKey(Math.random());
                                                setAlertKey(Math.random());

                                                setSelectedCrop('0');
                                                setSelectedDevice('0');
                                                setSelectedVillage('0');

                                                setSelectedAlert('');
                                                setScheduledDate(null);
                                                setMessage('');
                                                setSelectedFarmer([]);
                                                setSelectedFarmerOptions([]);
                                                setErrorMsg('');
                                                setSelectedLob(
                                                    loggedUser.roleid ===
                                                        'SUPER_ADMIN'
                                                        ? 'ALL'
                                                        : loggedUser.lineofbusiness
                                                );

                                                setLocationArray([]);
                                                setDeviceArray([]);
                                                setFarmerArray([]);
                                            }}>
                                            {countryData &&
                                                countryData.map(
                                                    (country: any) =>
                                                        country.countrycode !==
                                                        selectedCountry ? (
                                                            <MenuItem
                                                                className='alert'
                                                                value={
                                                                    country.countrycode
                                                                }>
                                                                {
                                                                    country.country
                                                                }
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                disabled
                                                                value={
                                                                    country.countrycode
                                                                }>
                                                                {
                                                                    country.country
                                                                }
                                                            </MenuItem>
                                                        )
                                                )}
                                        </Select>
                                    </div>
                                </div>

                                <div className='device-container'>
                                    <div className='row-name'>
                                        Business Unit
                                    </div>
                                    <div className='boxtext-div1'>
                                        <Select
                                            value={selectedLob}
                                            className='alert-style'
                                            sx={{
                                                width: '100%',
                                                fontFamily: 'appRegular',
                                                fontSize: 10,
                                            }}
                                            onChange={(event: any) => {
                                                setSelectedLob(
                                                    event?.target.value
                                                );
                                                setFarmerKey(Math.random());
                                                setCropKey(Math.random());
                                                setDeviceKey(Math.random());
                                                setVillageKey(Math.random());
                                                setAlertKey(Math.random());

                                                setSelectedCrop('0');
                                                setSelectedDevice('0');
                                                setSelectedVillage('0');

                                                setSelectedAlert('');
                                                setScheduledDate(null);
                                                setMessage('');
                                                setSelectedFarmer([]);
                                                setSelectedFarmerOptions([]);
                                                setErrorMsg('');
                                                getCreateCustomAdvisoryData();
                                                setLocationArray([]);
                                                setDeviceArray([]);
                                                setFarmerArray([]);
                                            }}>
                                            {lobData &&
                                                lobData.map((lob: any) =>
                                                    lob !== selectedLob ? (
                                                        <MenuItem
                                                            className='alert'
                                                            value={lob}>
                                                            {lob}
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            disabled
                                                            value={lob}>
                                                            {lob}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div>
                            <div className='crop-container'>
                                <div className='row-name'>Alert Type</div>
                                <div className='boxtext-div'>
                                    <Select
                                        value={selectedAlert}
                                        className='alert-style'
                                        key={alertKey}
                                        sx={{
                                            width: '100%',
                                            fontFamily: 'appRegular',
                                            fontSize: 10,
                                        }}
                                        onChange={handleAlertChange}>
                                        <MenuItem
                                            className='alert'
                                            value='DISEASE'>
                                            Disease
                                        </MenuItem>
                                        <MenuItem
                                            className='alert'
                                            value='PEST'>
                                            Pest
                                        </MenuItem>
                                        <MenuItem
                                            className='alert'
                                            value='RAINFALL'>
                                            Rainfall
                                        </MenuItem>
                                        <MenuItem
                                            className='alert'
                                            value='OTHERS'>
                                            Others
                                        </MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='create-custom-advisory-row1'>
                            <div className='crop-container'>
                                <div className='row-name'>Crop</div>
                                <div className='boxtext-div'>
                                    <Autocomplete
                                        id='tags-standard-crop'
                                        key={cropKey}
                                        options={cropArray}
                                        getOptionLabel={(crop) => crop.cropname}
                                        sx={{ width: '100%' }}
                                        classes={{
                                            paper: classes.paper,
                                        }}
                                        onChange={handleOnCropClick}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={classes.textfield}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='device-container'>
                                <div className='row-name'>Device Serial No</div>
                                <div className='boxtext-div1'>
                                    <Autocomplete
                                        id='tags-standard-device'
                                        key={deviceKey}
                                        options={deviceArray}
                                        getOptionLabel={(device) =>
                                            device.deviceserialno
                                        }
                                        classes={{
                                            paper: classes.paper,
                                        }}
                                        sx={{ width: '100%' }}
                                        onChange={handleOnDeviceClick}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={classes.textfield}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='create-custom-advisory-row2'>
                            <div className='village-container'>
                                <div className='row-name'>Village</div>
                                <div className='boxtext-div'>
                                    <Autocomplete
                                        id='tags-standard-village'
                                        key={villageKey}
                                        options={locationArray}
                                        getOptionLabel={(village) =>
                                            village.locationhierlevel4desc
                                        }
                                        sx={{ width: '100%' }}
                                        classes={{ paper: classes.paper }}
                                        onChange={handleOnVillageClick}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={classes.textfield}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='farmer-container'>
                                <div className='row-name'>Farmer</div>
                                <div className='boxtext-div'>
                                    <Autocomplete
                                        multiple
                                        id='tags-standard'
                                        value={selectedFarmerOptions}
                                        key={farmerKey}
                                        options={farmerArray}
                                        getOptionLabel={(farmer) =>
                                            farmer.firstname +
                                            ' ' +
                                            farmer.lastname
                                        }
                                        classes={{
                                            paper: classes.paper,
                                        }}
                                        sx={{ width: '100%' }}
                                        onChange={handleFarmerOnClick}
                                        getOptionDisabled={(option) => {
                                            if (
                                                selectedFarmerOptions.some(
                                                    (farmer: any) =>
                                                        farmer.farmerid ===
                                                        'ALL'
                                                )
                                            ) {
                                                return true;
                                            }
                                            if (
                                                selectedFarmerOptions.some(
                                                    (farmer: any) =>
                                                        farmer.farmerid ===
                                                        option.farmerid
                                                )
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ height: 10 }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='messagefarmers-advisory'>
                        Message to farmers
                        <span
                            style={{
                                color: '#A8A8A8',
                                fontSize: 'small',
                                paddingLeft: 3,
                            }}></span>
                    </div>
                    <TextAreaMessage
                        style={{
                            marginTop: 6,
                            fontFamily: 'appRegular',
                            fontSize: 1,
                            marginBottom: 40,
                        }}
                        className='advisory_comment_textarea'
                        inputProps={{
                            maxLength: CHARACTER_LIMIT,
                        }}
                        helperText={`${message.length}/${CHARACTER_LIMIT}`}
                        onChange={handleChange(message)}
                        margin='normal'
                        variant='outlined'
                        multiline
                        minRows={4}
                        value={message}
                        placeholder='Enter message here....'
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label='Schedule Date and Time'
                            value={scheduledDate}
                            onChange={(newValue) => {
                                let st = dayjs(newValue);

                                setScheduledDate(st);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <div className='buttton_layout'>
                        <div className='reset-button'>
                            <div className='arrow-button'>
                                <Button
                                    className='button-style-advisory'
                                    style={{
                                        color: '#484848',
                                        columnGap: 20,
                                        textTransform: 'none',
                                    }}
                                    onClick={handleReset}>
                                    <SubdirectoryArrowLeftIcon></SubdirectoryArrowLeftIcon>
                                    Reset
                                </Button>
                            </div>
                        </div>
                        <div className='create-button'>
                            <Button
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    textTransform: 'none',
                                }}
                                onClick={handleCreateClick}>
                                Create
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ModalCustom;
