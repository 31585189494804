/** @format */

import React from 'react';

function VendorSerial({ deviceList }) {
    
    return (
        <div>
            <div className='device-vendor'>
                <div>
                    <span className='nameFL'>Device:</span>
                    <span className='valueFL'>{deviceList?.deviceserialno? deviceList?.deviceserialno: 'NA'}</span>
                </div>
                <div>
                    <span className='nameFL'>Vendor:</span>
                    <span className='valueFL'>{deviceList?.vendor?deviceList?.vendor : 'NA' }</span>
                </div>
            </div>
        </div>
    );
}

export default VendorSerial;
