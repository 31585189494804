/** @format */

export const setDefaultCropList = (cropListArray: any[]) => ({
    type: 'setDefaultCropList',
    payload: cropListArray,
});

export const chooseCrop = (cropObj: any) => {
    return { type: 'chooseCrop', payload: cropObj };
};

export const typecalendarname = (calendarname:any) => {
    return {
        type: 'typecalendarname',
        payload: calendarname
    };
};

export const chooseVariety = (variety:any) => {
    return { type: 'chooseVariety', payload:variety };
}

export const chooseSeason = (season:any) => {
    return { type: 'chooseSeason', payload:season };
}

export const chooseYear = (year: number) => {
    return {
        type: 'chooseYear',
        payload: year,
    };
};

export const chooseSeasonStartDate = (date: any) => ({
    type: 'chooseSeasonStartDate',
    payload: date,
});

export const chooseSeasonEndDate = (date: any) => ({
    type: 'chooseSeasonEndDate',
    payload: date,
});
export const chooseCountry = (value:any) => ({
    type: 'chooseCountry',
    payload: value,
});

export const chooseState = (value:any) => {
    return {
        type: 'chooseState',
        payload: value
    };
};

export const setAllGeolevels = (geolevelsArray: any[]) => ({
    type: 'setAllGeolevels',
    payload: geolevelsArray,
});

export const firstOrResetData = (geolevelsArray: any[]) => ({
    type: 'firstOrResetData',
    payload: geolevelsArray,
});

export const reset = () => ({
    type: 'reset',
});

export const addSubStageRow = () => ({
    type: 'ADD_SUB_STAGE_ROW',
    payload: {},
});

export const removeSubStageRow = () => ({
    type: 'REMOVE_SUB_STAGE_ROW',
    payload: {},
});

export const updateSubStageRow = (value:any,label:any,index:any) => ({
    type: 'UPDATE_SUB_STAGE_ROW',
    payload: {
        input:value,
        i:index,
        label:label
    },
});

export const resetRowSubStage = () => ({
    type: 'RESET_SUB_STAGE_ROW',
    payload: {},
});

export const updateNotifications = (value:any,mainIdx:any,language:any) => ({
    type: 'UPDATE_NOTIFICATIONS',
    payload: {
        input: value,
        mainIdx:mainIdx,
        language:language
    },
});

export const RESET_ACTION = {
    type: 'RESET',
};
